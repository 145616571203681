<div class="upload-panel">
  <div *ngIf="preview || isAllFileType" class="img-ul-container" [ngStyle]="style?.previewPanel || null">
    <div
      *ngFor="let file of files"
      class="img-ul-image"
      [style.width] = "imageWidth"
      [style.height] = "imageHeight"
      [style.lineHeight] = "imageLineHeight"
      [style.background-color] = "backgroundColor" 
      (click)="previewFileClicked(file)"
      [ngStyle]="{'background-image': isAllFileType || isVideo ? null : 'url('+ file.src +')'}"
    >
      <a *ngIf="isAllFileType" href="{{file.src}}" download>{{file.name}}</a>
      <div *ngIf="!file.pending"
        [ngClass]="{'img-ul-disabled': disabled}"
        class="img-ul-x-mark"
        (click)="deleteFile(file)">
        <span class="img-ul-close"></span>
      </div>
      <video *ngIf="isVideo" controls [src]="file.src" [style.width]="imageWidth">
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div *ngIf="file.pending" class="img-ul-loading-overlay">
        <div class="img-ul-spinning-circle"></div>
      </div>
    </div>
  </div>

  <label *ngIf="fileCounter != max" 
    [class.img-ul]="!onlyIcon"
    [style.width] = "imageWidth"
    [style.height] = "imageHeight"
    [style.lineHeight] = "imageLineHeight"
    >
    <!-- <i class="fa fa-plus"></i> -->
    <div *ngIf="!isAllFileType" class="d-flex justify-content-center align-items-center h-100">
      <div *ngIf="!onlyIcon" style="display:block;width:24px;height:24px;background-size: 100%;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><path d="M42 20H22V0h-2v20H0v2h20v20h2V22h20z"/></svg>
      </div>
      <div *ngIf="onlyIcon" style="display:block;width:24px;height:24px;background-size: 100%;">
        <svg [style.fill]="iconColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path d="M50.975 20.694c-.527-9-7.946-16.194-16.891-16.194-5.43 0-10.688 2.663-13.946 7.008-.074-.039-.153-.065-.228-.102a8.621 8.621 0 00-.605-.269 8.946 8.946 0 00-.961-.317c-.116-.031-.231-.063-.349-.09a8.938 8.938 0 00-.683-.124c-.102-.015-.202-.035-.305-.047A9.278 9.278 0 0016 10.5c-4.962 0-9 4.037-9 9 0 .129.007.255.016.381C2.857 22.148 0 26.899 0 31.654 0 38.737 5.762 44.5 12.845 44.5H18a1 1 0 100-2h-5.155C6.865 42.5 2 37.635 2 31.654c0-4.154 2.705-8.466 6.432-10.253L9 21.13v-.63c0-.123.008-.249.015-.375l.009-.175-.012-.188C9.007 19.675 9 19.588 9 19.5c0-3.859 3.14-7 7-7 .309 0 .614.027.917.067.078.01.155.023.232.036.268.044.532.102.792.177.034.01.069.016.102.026a6.86 6.86 0 011.043.421A6.995 6.995 0 0123 19.5a1 1 0 102 0 8.98 8.98 0 00-3.2-6.871C24.666 8.879 29.388 6.5 34.084 6.5c7.744 0 14.178 6.135 14.848 13.887-1.022-.072-2.553-.109-4.083.125a1 1 0 10.301 1.977c2.224-.336 4.543-.021 4.684-.002C54.49 23.372 58 27.661 58 32.472 58 38.001 53.501 42.5 47.972 42.5H44a1 1 0 100 2h3.972C54.604 44.5 60 39.104 60 32.472c0-5.489-3.827-10.412-9.025-11.778z"/><path d="M31.708 30.794a1.001 1.001 0 00-1.416 0l-7.999 7.999a.999.999 0 101.414 1.414L30 33.914V54.5a1 1 0 102 0V33.914l6.293 6.293a.997.997 0 001.414 0 .999.999 0 000-1.414l-7.999-7.999z"/></svg>
      </div>
    </div>
    <input
      [class.d-none]="!isAllFileType"
      type="file"
      [disabled]="disabled"
      [accept]="supportedExtensions"
      [multiple]="max > 1"
      (change)="onFileChange(input.files)"
      #input>
  </label>
  
</div>
<!--
<div
     fileDrop
     [accept]="supportedExtensions"
     (fileOver)="onFileOver($event)"
     (fileDrop)="onFileChange($event)"
     [ngClass]="cssClass"
     [ngClass]="{'img-ul-file-is-over': fileOver}"
     [ngStyle]="style?.layout"
>
  <div class="img-ul-file-upload img-ul-hr-inline-group">
    <label *ngIf="fileCounter != max"
      class="img-ul-upload img-ul-button"
      [ngStyle]="style?.selectButton"
      [ngClass]="{'img-ul-disabled': disabled}">
      <span [innerText]="buttonCaption"></span>
      <input
        type="file"
        [disabled]="disabled"
        [accept]="supportedExtensions"
        [multiple]="max > 1"
        (change)="onFileChange(input.files)"
        #input>
    </label>
    <button *ngIf="fileCounter > 0"
      [disabled]="disabled"
      class="img-ul-clear img-ul-button"
      (click)="deleteAll()"
      [ngStyle]="style?.clearButton"
      [innerText]="clearButtonCaption">
    </button>
    <div class="img-ul-drag-box-msg" [innerText]="dropBoxMessage"></div>
  </div>

  <p class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage"></p>

</div> -->

<div class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" >{{fileTooLargeMessage}}</div>
<div class="img-ul-file-too-large" *ngIf="isInvalidExtension" [innerText]="invalidExtensionMessage"></div>
