import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { TokenStorageService } from './token-storage.service';
import { UserInfo } from '../entity/user-info';
import { Utils } from '../helper/utils';
import { BACKEND_URL } from '../helper/yk-token';
import { AlertService } from './alert.service';
import { UserService } from './user.service';
import { WxUserInfo } from '../entity';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    currentUser!: UserInfo;
    wx: any;

    constructor(
      @Inject(BACKEND_URL) private backendUrl: string,
      private http: HttpClient,
      private router: Router,
      private userService: UserService,
      private tokenStorage: TokenStorageService,
      private alertService: AlertService
        ) {
      this.wx = (window as { [key: string]: any })['wx'];
    }

    register(user: UserInfo) {
        return this.http.post(`${this.backendUrl}signup`, user);
    }

    async notRobot(imageCode: string) {
      let body = new HttpParams();
      body = body.set('imageCode', imageCode);
      await this.http.get(`${this.backendUrl}code/validate`, { params: body, headers: this.headers }).toPromise();
    }

    async login(username: string, password: string, rememberMe: boolean, imageCode: string, returnUrl: string, authType?: string) {
      let body = new HttpParams();
      let auth = 'authentication/form';
      if (authType === 'sms') {
        auth = 'authentication/mobile';
        body = body.set('mobile', username);
        body = body.set('smsCode', password);
      }
      body = body.set('username', username);
      body = body.set('password', password);
      body = body.set('remember-me', rememberMe + '');
      body = body.set('imageCode', imageCode);
      const res = await this.http.post<any>(`${this.backendUrl}${auth}`,
        body, { headers: this.headers }).toPromise();
      await this.reserveUser(returnUrl);
      return res;
    }

    async wxLogin(code: string, state: string, token: string, returnUrl: string, userInfo?: WxUserInfo) {
      if (code || token) {
        await this.http.post<any>(`${this.backendUrl}authentication/thirdparty`,
          {
            code,
            state,
            token,
            userInfo
          }).toPromise();
        await this.reserveUser(returnUrl);
      } else {
        const that = this;
        const isMiniProgram = /miniProgram/i.test(navigator.userAgent);
        if (isMiniProgram) {
          // is miniProgram
          that.wx.miniProgram.redirectTo({url: '/pages/login/login?action=login'});
        } else {
          that.http.get<any>(`${that.backendUrl}tenant/appid`).subscribe(res => {
            let ourl = window.location.href;
            ourl = ourl.indexOf('?') > 0 ? ourl.substring(0, ourl.indexOf('?') - 1) : ourl;
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='
              + res.content + '&redirect_uri='
              + encodeURIComponent(ourl)
              + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
          });
        }
      }
    }

    sendSmsCode(mobile: string) {
      let body = new HttpParams();
      body = body.set('mobile', mobile);
      this.http.get(`${this.backendUrl}code/sms`, {params: body}).subscribe();
    }

    logout() {
      this.http.get(`${this.backendUrl}logout`).subscribe(
        data => {
          // remove user from local storage to log user out
          this.tokenStorage.signOut();
          this.router.navigate(['/login']);
        },
        error => {
          this.alertService.error(error);
        });
    }

    async reserveUser(returnUrl?: string) {
      const that = this;
      try {
        const data = await this.userService.getMe().toPromise();
        if (data.orgId === 0 && data.username === Utils.defaultAdmin && !Utils.mutlipleTenant) {
          data.orgId = 1;
          await this.userService.upsert(data).toPromise();
        }
        this.tokenStorage.saveUsername(data.username);
        this.tokenStorage.saveToken(data.username);
        this.tokenStorage.saveUser(data);
        this.tokenStorage.saveObjectItem('currentOrgId', data.orgId);
  
        const isMiniProgram = /miniProgram/i.test(navigator.userAgent);
        if (Utils.systemId === 'mb' && isMiniProgram) {
          // is miniProgram
          if (!data.phone || data.phone.length === 0) {
            that.wx.miniProgram.redirectTo({url: `/pages/login/login?action=requirephone&username=${data.username}`});
          }
        }
        const action = this.tokenStorage.getItem('nav');
        if (action) {
          this.tokenStorage.removeObject('nav');
          returnUrl = action;
        }
        // login successful so redirect to return url
        if (!returnUrl || returnUrl.startsWith('/login')) {
          returnUrl = '/';
          // data.type === 'System' ? '/main/dashboard' : '/main/article-manage/ad';
        }

        // this.router.navigateByUrl(returnUrl);
        this.router.navigate([returnUrl]);
      } catch (error: any) {
        this.alertService.error(error);
      }
    }
}
