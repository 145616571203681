import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  maxWidth: string;
  width: string;
  caption: string;
  question: string;
  prop: string;
  buttons: string[];
  input: boolean;
  image: string;
}

@Component({
  selector: 'yka-common-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  input1!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
  inputChange(event: any) {
    this.input1 = event.target.value;
  }
}
