import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdImage } from '../../entity/ad-image';
import { slideImage } from '../../helper/animations';

@Component({
  selector: 'yka-mobile-ad-board',
  templateUrl: './ad-board.component.html',
  styleUrls: ['./ad-board.component.scss'],
  animations: [
    slideImage
  ]
})
export class AdBoardComponent implements OnInit {

  @Input() items: AdImage[] = [];
  @Input() duration: number = 5000;
  @Input() maxWidth = '46.875rem'; // 750px 2 times width of iPhone 6
  @Input() maxHeight = '21rem'; // 44.8% of width
  @Input() ratio = '44.8vw';
  @Input() radius = '0';
  @Input() objectFit = 'cover';

  @Output() adclick = new EventEmitter<AdImage>();

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
  current = 0;

  constructor() {
  }

  ngOnInit() {
    if (this.duration > 0) {
      setInterval(() => {
        this.Next();
      }, this.duration);
    }
  }

  adClicked(item: AdImage) {
    if (item.id) {
      this.adclick.emit(item);
    }
  }

  swipe(action: string = this.SWIPE_ACTION.RIGHT) {
    // next
    if (action === this.SWIPE_ACTION.RIGHT) {
      this.Prev();
    }
    // previous
    if (action === this.SWIPE_ACTION.LEFT) {
      this.Next();
    }
  }

  ImageState(index: number) {
    if (this.items && this.items.length) {
      if (this.current === 0) {
        return index === 0 ? 'on' :
        index === 1 ? 'next' :
        index === this.items.length - 1 ? 'prev' :
        'off';
       } else if (this.current === this.items.length - 1) {
        return index === this.items.length - 1 ? 'on' :
        index === this.items.length - 2 ? 'prev' :
        index === 0 ? 'next' :
        'off';
       }
      switch (index - this.current) {
        case 0:
          return 'on';
        case 1:
          return 'next';
        case -1:
          return 'prev';
        default:
          return 'off';
      }
    } else {
      return 'off';
    }
  }

  Next() {
    if (this.items && this.items.length) {
      this.current = (this.current + 1) % this.items.length;
    }
  }

  Prev() {
    if (this.items && this.items.length) {
      this.current = this.current - 1 < 0 ? this.items.length - 1 : this.current - 1;
    }
  }

}
