<app-header></app-header>

<div class="d-flex flex-column align-items-center justify-content-center container">
  <div class="category">{{parent.id ? parent.name : category.name}}</div>
  <div class="d-flex">
    <div *ngFor="let item of scitems" class="sub-category" [routerLink]="['/list/' + (linkMap.get(parentId) || ''), item.parent, item.id]" routerLinkActive="router-link-active" >
      {{item.name}}
    </div>
  </div>
</div>

<app-nav-loc-bar [parent]="parent" [page]="category"></app-nav-loc-bar>

<div class="row container content-row">
  <div class="col">
    <div *ngIf="linkMap.get(parentId) === 'layout-1'">
      <div class="row">
        <div *ngFor="let item of items; let i=index" class="col-lg-4 col-md-6 col-sm-12 d-flex flex-column cursor-pointer"
          [routerLink]="['/webpage', item.id]" >
          <div *ngIf="item.images" class="box">
            <div class="box-content" [style.background-image]="'url(' + backendUrl + item.images[0] + ')'">
            </div>
          </div>
          <!-- <img class="img-ly" *ngIf="item.images" [src]="backendUrl + item.images[0]"> -->
          <div class="d-flex flex-column mx-1">
            <div class="elm-title">{{item.title}}</div>
            <div class="elm-content" [ngClass]="i % 2 === 0 ? 'light-content-color' : 'gray-content-color'">
              {{item.content ? item.content : h2t(item.src)}}
            </div>
          </div>
        </div>
      </div>  
      <div class="row" [style.display]="resultsLength > 6 ? '' : 'none'">
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[6, 8, 10, 12, 16, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>

    <div *ngIf="linkMap.get(parentId) === 'layout-2'">
      <div *ngFor="let item of items; let i=index" class="row">
        <div class="col d-flex flex-column mt-2">
          <div class="border-bottom pb-4">
            <a class="elm-title" [routerLink]="['/webpage', item.id]" >{{item.title}}</a>
            <div class="elm-subtitle">{{item.createdTime.substr(0, 10)}}</div>
            <!-- <div class="elm-content" [ngClass]="i % 2 === 0 ? 'light-content-color' : 'gray-content-color'"> -->
            <div class="elm-content">
              {{item.content ? item.content : h2t(item.src)}}
            </div>
          </div>
        </div>
      </div>
      <div class="row" [style.display]="resultsLength > 5 ? '' : 'none'">
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>  

    <div *ngIf="linkMap.get(parentId) === 'layout-3'">
      <div *ngFor="let item of items; let i=index" class="row">
        <div class="col d-flex flex-column mt-2">
          <div class="border-bottom pb-4">
            <a class="elm-title" [routerLink]="['/recruitment', item.id]" >{{item.title}}</a>
            <div class="elm-subtitle">{{item.createdTime.substr(0, 10)}}</div>
            <div class="elm-content-rc">
              {{h2t(item.description)}}
            </div>
          </div>
        </div>
      </div>
      <div class="row" [style.display]="resultsLength > 5 ? '' : 'none'">
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>  

    <div *ngIf="linkMap.get(parentId) === 'layout-4'">
      <div *ngFor="let item of items; let i=index" class="row">
        <div class="col d-flex justify-content-center" [routerLink]="['/simple-page', item.id]">
          <img class="title-img" [src]="backendUrl + item.images[0]" >
        </div>
      </div>
      <div class="row" [style.display]="resultsLength > 5 ? '' : 'none'">
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>  

  </div>
</div>

<app-footer></app-footer>