import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { AbstractHttpService } from './abstract-http.service';
import { UserInfo, WxUserInfo } from '../entity';
import { BACKEND_URL } from '../helper';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractHttpService<UserInfo> {

  constructor(@Inject(BACKEND_URL) backendUrl: string, http: HttpClient) {
    super(backendUrl, http, 'user');
  }

  getMe() {
    return this.http.get<UserInfo>(`${this.backendUrl}user/me`);
  }

  getWeChatInfo(id: number) {
    return this.http.get<WxUserInfo>(`${this.backendUrl}user/wxinfobyuserid/${id}`);
  }

  findWeChatInfoByUserIds(ids: number[]) {
    return this.http.post<WxUserInfo[]>(`${this.backendUrl}user/wxinfobyuserids`, ids);
  }

  findUserByIds(ids: number[]) {
    return this.http.post<UserInfo[]>(`${this.backendUrl}user/byuserids`, ids);
  }

  findByType(type: string) {
    return this.http.get<UserInfo[]>(`${this.backendUrl}user/bytype/${type}`);
  }

  updatePassword(id: number, old: string, password: string) {
    let params = new HttpParams();
    params = params.set('old', old);
    params = params.set('password', password);
    return this.http.put(`${this.backendUrl}user/password/${id}`, params, {responseType: 'text'});
  }

  resetPassword(id: number) {
    return this.http.get<any>(`${this.backendUrl}user/resetpassword/${id}`);
  }

  getRole(id: number) {
    return this.http.get<string[]>(`${this.backendUrl}user/${id}/role`);
  }

  findUserByTypeAndCreatedRange(type: string, start: string, end: string) {
    return this.http.get<UserInfo[]>(`${this.backendUrl}${this.pathVar}/findbytypecreatedrange`, {params : {type, start, end}});
  }

}
