import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TopMenuInfo } from '../entity';
import { AbstractHttpService } from 'projects/yka-base-common/src/public-api';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper';

@Injectable({
  providedIn: 'root'
})
export class TopMenuService extends AbstractHttpService<TopMenuInfo> {

  constructor(
    @Inject(BACKEND_URL) backendUrl: string,
    http: HttpClient
  ) {
    super(backendUrl, http, 'topmenu');
  }


}
