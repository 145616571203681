import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebpageInfo } from 'projects/share-lib/src/lib/entity';
import { WebpageService } from 'projects/share-lib/src/lib/service';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper';

@Component({
  selector: 'app-simple-page',
  templateUrl: './simple-page.component.html',
  styleUrls: ['./simple-page.component.scss']
})
export class SimplePageComponent implements OnInit {

  id: number = -1;
  item: WebpageInfo = {} as WebpageInfo;

  constructor(
    private route: ActivatedRoute,
    @Inject(BACKEND_URL) public backendUrl: string,
    private service: WebpageService
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async param => {
      this.id = Number(param.get('id'));
      await this.getItem();
    });
  }

  async getItem() {
    this.item = await this.service.getById(this.id).toPromise();
  }
  
}
