<h1 *ngIf="data.caption" class="title" mat-dialog-title>{{data.caption | translate}}</h1>
<div mat-dialog-content class="my-2">
  <p>{{data.question | translate:{'prop': data.prop ? data.prop : ''} }}</p>
  <div *ngIf="data.input">
    <mat-form-field class="w-100">
      <input matInput (change)="inputChange($event)" />
    </mat-form-field>
  </div>
  <div *ngIf="data.image" class="d-flex justify-content-center">
    <img class="img-div" [src]="data.image" >
  </div>
</div>
<div class="d-flex justify-content-around mt-3 dialog-action" [class.mb-2]="data.buttons.length === 1">
  <div *ngFor="let item of data.buttons; let i=index" [mat-dialog-close]="i"
    class="border-top action-btn" [class.border-left]="i > 0" [class.primary-btn]="i === 0">
    {{item | translate}}
  </div>
</div>