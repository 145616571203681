<app-header></app-header>

<div *ngIf="item?.src" class="full-bg" [style.background-image]="item.backgroundImage ? 'url(' + backendUrl + item.backgroundImage + ')' : ''">
  <div class="row container content-row">
    <div class="col bg-white">
      <!-- <div class="" [innerHTML]="item.src"></div> -->
      <yka-common-rich-editor [value]="item?.src" [disabled]="true"></yka-common-rich-editor>
    </div>
  </div>
</div>

<app-footer></app-footer>