import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TokenStorageService } from 'projects/yka-base-common/src/lib/service/token-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() colors!: any;
  @Input() friendLinks: {name: string, link: string}[] = [];
  @Input() icps: {name: string, link: string}[] = [];
  constructor(
    private tokenStorage: TokenStorageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icps || changes.colors || changes.friendLinks) {
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.icps = this.tokenStorage.getObjectItem('icps') || [];
    this.colors = this.tokenStorage.getObjectItem('colors');
    if (this.colors) {
      document.documentElement.style.setProperty('--font-color', this.colors.fontColor);
      document.documentElement.style.setProperty('--bg-color', this.colors.bgColor);
      document.documentElement.style.setProperty('--a-color', this.colors.aColor);
    }
  }

}
