<div class="d-flex align-items-center header" >
  <!-- <mat-toolbar-row> -->
    <div class="d-flex align-items-center container ">
      <div class="mr-5" [routerLink]="[ '/home' ]">
        <img [src]="logo" class="logo" >
      </div>
      <div class="flex-fill d-flex flex-sm-row align-items-center">
        <div class="header-dropdown">
          <a class="dropbtn " [routerLink]="[ '/home' ]" routerLinkActive="router-link-active">{{'home' | translate}}</a>
        </div>

        <div *ngFor="let item of hitems" class=" header-dropdown">
          <ng-container *ngIf="item.length > 1; else elseTemplate" class="">
            <div class="dropdown">
              <a class="dropbtn" [routerLink]="['/list', linkMap.get(item[0].id), item[0].id, -1]" routerLinkActive="router-link-active">
                {{item[0].name}}
                <span class="caret"></span>
              </a>
              <div class="dropdown-content">
                <div *ngFor="let itm of item; let i=index">
                  <a *ngIf="i > 0" [routerLink]="['/list', linkMap.get(itm.parent), itm.parent, itm.id]" routerLinkActive="router-link-active">
                    {{item[i].name}}
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <a class="dropbtn" [routerLink]="['/list', linkMap.get(item[0].id), item[0].id, item[0].id]" routerLinkActive="router-link-active" >
              {{ item[0].name }}
            </a>
          </ng-template>
        </div>
        
        <div *ngIf="builtinContactUs" class="header-dropdown ">
          <a class="dropbtn" [routerLink]="[ '/contact-us' ]" routerLinkActive="router-link-active">{{'contactUs' | translate}}</a>
        </div>
        
        <div *ngIf="specialLinks.length > 0" class="ml-auto" >
          <a *ngFor="let sl of specialLinks; let i=index" [style]="sl.style" [href]="sl.link" target="_blank" >{{sl.caption}}</a>
        </div>
      </div>

    </div>
  <!-- </mat-toolbar-row> -->
  </div>

<div *ngIf="(showHeaderImage !== '2' || (showHeaderImage === '2' && location === 'home')) && imgs && imgs.length > 0">
  <yka-mobile-ad-board [items]="imgs" [ratio]="location === 'home' ? ratio : ratioOther" [maxWidth]="'100%'" [maxHeight]="'auto'"></yka-mobile-ad-board>
</div>

