import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AdPageService } from './ad-page.service';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper/yk-token';
import { AlertService } from 'projects/yka-base-common/src/lib/service/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AdService {

  constructor(
    private router: Router,
    @Inject(BACKEND_URL) private backendUrl: string,
    private adPageService: AdPageService,
    private alertService: AlertService
  ) { }

  async initAdItems() {
    const aditems: any[] = [];
    const items = await this.findAds();
    if (items) {
      for (const item of items) {
        aditems.push({
          id: item.id,
          src: this.backendUrl  + item.picture,
          alt: '',
          title: '',
          description: '',
          link: '',
          visible: false
        });
      }
    }
    return aditems;
  }

  async findAds() {
    try {
      const items = await this.adPageService.findAds().toPromise();
      return items;
    } catch (error) {
      this.alertService.error(error);
      return [];
    }
  }

  async getAdPageItems(locations: string[]) {
    try {
      const items = await this.adPageService.findByLocation(locations).toPromise();
      return items;
    } catch (error) {
      this.alertService.error(error);
      return [];
    }
  }

  loadArticle(id: number) {
    if (id === -1) {
      this.router.navigate(['/location/merchant', -1]);
    } else {
      this.router.navigate(['/location/article', id]);
    }
  }
}
