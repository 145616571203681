<div class="crop-page">
  <div class="d-flex flex-row justify-content-around action-bar">
    <div (click)="close()">
      <mat-icon class="action-icon">clear</mat-icon>
    </div>
    <div (click)="zoomIn()">
      <mat-icon class="action-icon" [class.d-none]="isMobile()" svgIcon="zoom-in"></mat-icon>
    </div>
    <div (click)="zoomOut()">
      <mat-icon class="action-icon" [class.d-none]="isMobile()" svgIcon="zoom-out"></mat-icon>
    </div>
    <div (click)="crop()">
      <mat-icon class="action-icon">crop</mat-icon>
    </div>
  </div>

  <div #rootContainer class="root" [ngStyle]="{
      width: config.areaWidth,
      height: config.areaHeight
    }">
    <div #imgContainer class="img-container"
      (slidestart)="moveStart()"
      (slide)="move($event)">
      <canvas #imgCanvas></canvas>
    </div>
    <div #area class="img-area" [ngStyle]="{
        width: config.width + 'px',
        height: config.height + 'px'
      }">
      <div *ngIf="isCropping" class="text-center pt-2" style="color: white;">
        <div><mat-spinner></mat-spinner></div>
      </div>
    </div>  
  </div>  
</div>

