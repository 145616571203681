import { BreakpointObserver } from '@angular/cdk/layout';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FastLinkInfo, FriendLinkInfo, LayoutInfo, PageCategoryInfo, WebpageInfo } from 'projects/share-lib/src/lib/entity';
import { FastLinkService, FriendLinkService, HeaderImageService, LayoutService, PageCategoryService, WebpageService } from 'projects/share-lib/src/lib/service';
import { AdImage } from 'projects/yka-base-common/src/lib/entity';
import { BACKEND_URL, TokenStorageService, Utils } from 'projects/yka-base-common/src/public-api';
import { ImageSettingService, ValueSettingService } from 'projects/yka-common/src/public-api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  h2t = Utils.html2text;
  idx: number = 0;
  tiles = [0];

  logo: string = '/assets/img/yk-logo-l.svg';
  newsDefault: string = '';
  hitems: {id: number, parent: number, name: string}[][] = [];
  hMap = new Map<number, {id: number, parent: number, name: string}[]>();
  pcMap = new Map<number, PageCategoryInfo>();
  imgs: AdImage[] = [];
  linkMap = new Map<number, string>();

  elms: {category: number, categoryName: string, layout: string, pages: WebpageInfo[]}[] = [];
  fls: FriendLinkInfo[] = [];
  icps: FriendLinkInfo[] = [];
  fastlinks = new Map<number, FastLinkInfo[]>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(BACKEND_URL) public backendUrl: string,
    private translate: TranslateService,
    private tokenStorage: TokenStorageService,
    private isService: ImageSettingService,
    private pcService: PageCategoryService,
    private hiService: HeaderImageService,
    private layoutService: LayoutService,
    private wpService: WebpageService,
    private flService: FriendLinkService,
    private ssService: ValueSettingService,
    private fastlinkService: FastLinkService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.breakpointObserver.observe([
      '(max-width: 768px)',
      '(min-width: 768px)',
      '(max-width: 992px)',
      '(min-width: 992px)',
      '(min-width: 1200px)'
    ]).subscribe(result => {
      if (this.breakpointObserver.isMatched('(min-width: 992px)')) {
        this.tiles = [0, 1, 2];
        if (this.elms.length > 0 && (this.idx+2) > this.elms.length) {
          this.idx = this.idx - 1;
        }
      }
      if (this.breakpointObserver.isMatched('(min-width: 768px)') && this.breakpointObserver.isMatched('(max-width: 992px)')) {
        this.tiles = [0, 1];
        if (this.elms.length > 0 && (this.idx+1) > this.elms.length) {
          this.idx = this.idx - 1;
        }
      }
      if (this.breakpointObserver.isMatched('(max-width: 768px)')) {
        this.tiles = [0];
      }
    });
    await this.getImage();
    await this.buildHeaderItems();
    await this.getAdImage();
    await this.getElement();
    await this.getFriendLink();
    await this.getFastLink();
  }

  async getImage() {
    try {
      const items = await this.isService.findByLocation(['logo', 'newsDefault']).toPromise();
      for (const itm of items) {
        if (itm.location === 'logo') {
          this.logo = this.backendUrl + itm.image;
          this.tokenStorage.saveItem('logo', this.logo);
        } else {
          this.newsDefault = this.backendUrl + itm.image;
        }
      }
      
    } catch (error) {
      console.log(error)
    }
  }

  async buildHeaderItems() {
    try {
      const items = await this.pcService.getByLang(this.translate.currentLang).toPromise();
      this.hMap = new Map<number, {id: number, parent: number, name: string}[]>();
      const hhitems: {id: number, parent: number, name: string}[][] = [];
      if (items) {
        for (const item of items) {
          if (item.status !== 'Active' || item.menu === -1) {
            continue;
          }
          this.pcMap.set(item.id, item);

          const itm = {id: item.id, parent: item.parent, name: item.name};
          if (itm.parent) {
            let pc = this.hMap.get(itm.parent);
            if (pc) {
              pc.push(itm);
            } else {
              pc = [itm];
              console.log('add enter');
            }
            this.hMap.set(itm.parent, pc);
          } else {
            this.hMap.set(itm.id, [itm]);
          }
        }
        this.hMap.forEach((v,k) => {
          hhitems.push(v);
        });
        this.hitems = hhitems;
        this.tokenStorage.saveObjectItem('hitems', this.hitems);
      }
      
    } catch (error) {
      console.log(error)
    }
  }

  async getAdImage() {
    try {
      let showHeaderImage = -1;
      const ss = await this.ssService.findByName('showHeaderImage').toPromise();
      if (ss) {
        showHeaderImage = ss.numberValue;
        this.tokenStorage.saveItem('showHeaderImage', showHeaderImage + '')
      }
      if (showHeaderImage === 1 || showHeaderImage === 2) {
        const items = await this.hiService.getByLang(this.translate.currentLang).toPromise();
        const timgs: AdImage[] = [];
        if (items && items.length > 0) {
          for (const itm of items[0].images) {
            const adimg = {
              src: this.backendUrl + itm
            } as AdImage;
            timgs.push(adimg);
          }
          this.imgs = timgs;
          this.tokenStorage.saveObjectItem('imgs', this.imgs);
        }
      } else {
        this.imgs = [];
        this.tokenStorage.removeObject('imgs');
      }      
    } catch (error) {
      console.log(error)
    }

  }

  async getElement() {
    try {
      // get home elements
      const lys = await this.layoutService.findByExample({page: 'home', lang: this.translate.currentLang, active: true} as LayoutInfo).toPromise();
      let ly1Elms = [];
      for (const ly of lys) {
        for (const pc of ly.elements) {
          const pgs = await this.wpService.getByCategoryLang(pc, this.translate.currentLang).toPromise();
          this.elms.push({
            category: pc,
            categoryName: this.pcMap.get(pc)?.name || '',
            layout: ly.name,
            pages: pgs
          });
        }
      }
      // get header nav items
      const lMap = new Map<number, string>();
      const sm: any[] = [];
      const lps = await this.layoutService.findByExample({page: 'list', lang: this.translate.currentLang, active: true} as LayoutInfo).toPromise();
      for (const lp of lps) {
        for (const itm of lp.elements) {
          lMap.set(itm, lp.name);
          const sa = [];
          sa.push(itm);
          sa.push(lp.name);
          sm.push(sa);
        }
      }
      this.linkMap = lMap;
      this.tokenStorage.saveObjectItem('linkMap', sm);
      
    } catch (error) {
      console.log(error)
    }
  }

  async getFriendLink() {
    try {
      this.fls = await this.flService.getByLang(this.translate.currentLang).toPromise();
      if ('zh' === this.translate.currentLang) {
        this.icps = await this.flService.getByLang('cn_icp').toPromise();
        this.tokenStorage.saveObjectItem('icps', this.icps);
      }
    } catch (error) {
      
    }
  }

  async getFastLink() {
    try {
      const items = await this.fastlinkService.getByLang(this.translate.currentLang).toPromise();
      if (items) {
        for (const itm of items) {
          let fla = this.fastlinks.get(itm.category);
          if (!fla) {
            fla = [];
            this.fastlinks.set(itm.category, fla);
          }
          fla.push(itm);
        }
      }
    } catch (error) {
      
    }
  }

}
