import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { WebpageInfo } from '../entity';
import { AbstractHttpService } from 'projects/yka-base-common/src/public-api';
import { BACKEND_URL, PageInfo } from 'projects/yka-base-common/src/lib/helper';

@Injectable({
  providedIn: 'root'
})
export class WebpageService extends AbstractHttpService<WebpageInfo> {

  constructor(
    @Inject(BACKEND_URL) backendUrl: string,
    http: HttpClient
  ) {
    super(backendUrl, http, 'webpage');
  }

  getByCategoryLang(category: number, lang: string) {
    return this.http.get<WebpageInfo[]>(`${this.backendUrl}${this.pathVar}/bycategorylang`, {params: {category: category + '', lang}});
  }

  queryByCategoryInWithPage(ids: number[], pageInfo: PageInfo) {
    let params = new HttpParams();
    Object.keys(pageInfo).forEach(itm => {
      params = params.set(itm, pageInfo[itm as keyof PageInfo] + '');
    });

    return this.http.post<any>(`${this.backendUrl}${this.pathVar}/bycategoryin`, ids, { params });
  }

}
