import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrgInfo } from '../entity/org-info';
import { AbstractHttpService } from './abstract-http.service';
import { BACKEND_URL } from '../helper/yk-token';

@Injectable({
  providedIn: 'root'
})
export class OrgService extends AbstractHttpService<OrgInfo> {
  constructor(
    @Inject(BACKEND_URL) backendUrl: string,
    http: HttpClient
  ) {
    super(backendUrl, http, 'org');
  }

}
