<ckeditor
  [editor]="Editor" 
  [config]="config"     
  [(ngModel)]="_value"
  [disabled]="isDisabled || false"
  (focus)="onFocus($event)"
  (blur)="onBlur($event)"
  (change)="onDataChange($event)"
  (ready)="onReady($event)">
</ckeditor>
