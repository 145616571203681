import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';
import { FormHelperService } from './form-helper.service';
import { IdInfo } from 'projects/yka-base-common/src/lib/entity/id-info';
import { AbstractHttpService } from 'projects/yka-base-common/src/lib/service/abstract-http.service';
import { AlertService } from 'projects/yka-base-common/src/lib/service/alert.service';
import { PageInfo } from 'projects/yka-base-common/src/lib/helper/page-info';

@Injectable({
  providedIn: 'root'
})
export class FormDataHelperService<T extends IdInfo, S extends AbstractHttpService<T>> {
  saveCaption: string = '';
  deleteCaption: string = '';
  successCaption: string = '';

  constructor(
    private formService: FormHelperService<T>,
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private translate: TranslateService) {
    this.translate.get('save').subscribe((res: string) => { this.saveCaption = res; });
    this.translate.get('delete').subscribe((res: string) => { this.deleteCaption = res; });
    this.translate.get('success').subscribe((res: string) => { this.successCaption = res; });
  }

  async searchItemsByIdOrName(service: S, id: string, name: string) {
    try {
      if (id && id != '') {
        const item = await service.getById(Number(id)).toPromise();
        return [item];
      }
      if (name !== '') {
        return await service.findByName(name).toPromise();
      }
    } catch (error) {
      this.alertService.error(error);
    }
  }

  async getItems(service: S, items: T[]) {
    try {
      const items = await service.getAll().toPromise();
      return items;
    } catch (error) {
      this.alertService.error(error);
      return [];
    }
  }

  async getItemsWithPage(service: S, items: T[], pageInfo: PageInfo) {
    try {
      const items = await service.queryWithPage(pageInfo).toPromise();
      return items;
    } catch (error) {
      this.alertService.error(error);
    }
  }

  async getItem(service: S, form: FormGroup, id: number) {
    try {
      const itm = await service.getById(id).toPromise();
      this.formService.setFormData(form, itm);
      return itm;
    } catch (error) {
      this.alertService.error(error);
      return {} as any;
    }
  }

  async getItemByExample(service: S, form: FormGroup, item: T) {
    try {
      const itms = await service.findByExample(item).toPromise();
      let itm = {} as T;
      if (itms && itms.length > 0) {
        itm = itms[0];
      }
      this.formService.setFormData(form, itm);
      return itm;
    } catch (error) {
      this.alertService.error(error);
      return {} as any;
    }
  }

  async saveItem(service: S, form: FormGroup, item: T, raw?: boolean) {
    try {
      this.formService.getFormData(form, item, raw);
      const itm = await service.upsert(item).toPromise();
      this.snackBar.open(this.saveCaption + this.successCaption, undefined, {
        duration: 2000,
      });
      item = itm;
      return itm;
    } catch (error) {
      this.alertService.error(error);
      return {} as any;
    }
  }

  async removeItem(service: S, item: T) {
    try {
      await service.delete(item.id).toPromise();
      this.snackBar.open(this.deleteCaption + this.successCaption);
    } catch (error) {
      this.alertService.error(error);
    }
  }

}
