import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OperationRecordInfo } from '../entity/operation-record-info';
import { AbstractHttpService } from 'projects/yka-base-common/src/lib/service/abstract-http.service';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper/yk-token';

@Injectable({
  providedIn: 'root'
})
export class OperationRecordService  extends AbstractHttpService<OperationRecordInfo> {
  constructor(@Inject(BACKEND_URL) backendUrl: string, http: HttpClient) {
    super(backendUrl, http, 'opr');
  }

  getAvgMiniLoginTimes() {
    return this.http.get<any[]>(`${this.backendUrl}${this.pathVar}/avgminilogin`);
  }

  getMiniLoginTimes() {
    return this.http.get<any[]>(`${this.backendUrl}${this.pathVar}/totalminilogin`);
  }

  getAdClicked() {
    return this.http.get<any[]>(`${this.backendUrl}${this.pathVar}/adclicked`);
  }
}
