import { Directive, Input } from '@angular/core';
import { ValidatorFn, AbstractControl, Validator } from '@angular/forms';

export function matchValidator(passwordControlName: string): ValidatorFn {
  return (control: AbstractControl) => {
    // get password from our password form control
    const password: string = control.parent && control.parent.get(passwordControlName)?.value;
    
    // get password from our confirmPassword form control
    const confirmPassword: string = control.value;
    // compare is the password match
    return password !== confirmPassword ? { doesNotMatch: true } : null;
  };
}

@Directive({
  selector: '[ykaManageCheckMatch]'
})
export class CheckMatchDirective implements Validator {
  @Input('ykaManageCheckMatch') passwordControlName!: string;

  validate(control: AbstractControl) {
    return this.passwordControlName ? matchValidator(this.passwordControlName)(control) : null;
  }

}
