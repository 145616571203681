<app-header></app-header>

<app-nav-loc-bar [parent]="parent" [page]="category" [isLink]="true"></app-nav-loc-bar>

<div class="row container content-row">
  <div class="col">
    <div *ngIf="item.showTitleImage && item.images && item.images.length > 0" class="row">
      <div class="col d-flex justify-content-center">
        <img class="title-img" [src]="backendUrl + item.images[0]" >
      </div>
    </div>
    <div *ngIf="item.title" class="row">
      <div class="col d-flex flex-column align-items-center border-bottom">
        <div class="elm-title">{{item.title}}</div>
        <div class="d-flex elm-subtitle">
          <div class="mr-2 sub-title-label">{{'committer' | translate}}</div>
          <div class="mr-3">{{item.committer}}</div>
          <div class="mr-2 sub-title-label">{{'addTime' | translate}}</div>
          <div class="mr-3">{{item.createdTime | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
          <div class="mr-2 sub-title-label">{{'pageSource' | translate}}</div>
          <div class="">{{item.source}}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!-- <div class="content border-top" [innerHTML]="item.src"></div> -->
        <div class="content">
          <yka-common-rich-editor [value]="item?.src" [disabled]="true"></yka-common-rich-editor>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>