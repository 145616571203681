import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LayoutInfo } from '../entity';
import { AbstractHttpService } from 'projects/yka-base-common/src/public-api';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper';

@Injectable({
  providedIn: 'root'
})
export class LayoutService extends AbstractHttpService<LayoutInfo> {

  constructor(
    @Inject(BACKEND_URL) backendUrl: string,
    http: HttpClient
  ) {
    super(backendUrl, http, 'layout');
  }

  getByLang(lang: string) {
    return this.http.get<LayoutInfo[]>(`${this.backendUrl}${this.pathVar}/bylang/${lang}`);
  }


}
