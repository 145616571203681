import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ImageSettingInfo } from '../entity/image-setting-info';
import { AbstractHttpService } from 'projects/yka-base-common/src/lib/service/abstract-http.service';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper/yk-token';

@Injectable({
  providedIn: 'root'
})
export class ImageSettingService  extends AbstractHttpService<ImageSettingInfo> {
  constructor(@Inject(BACKEND_URL) backendUrl: string, http: HttpClient) {
    super(backendUrl, http, 'imgsetting');
  }

  findByLocation(locations: string[]) {
    // const params = new HttpParams().set('location', location);
    const params = {locations};
    return this.http.get<ImageSettingInfo[]>(`${this.backendUrl}${this.pathVar}/bylocation`, { params });
  }
}
