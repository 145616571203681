import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CompanyContactInfo, CustomerContactInfo } from 'projects/share-lib/src/lib/entity';
import { CompanyContactService, CustomerContactService } from 'projects/share-lib/src/lib/service';
import { TokenStorageService, Utils } from 'projects/yka-base-common/src/public-api';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  itemForm!: FormGroup;
  orgId!: number;
  category = 'contactUs';
  item: CustomerContactInfo = {} as CustomerContactInfo;
  yitem: CompanyContactInfo = {} as CompanyContactInfo;
  submitCaption!: string;
  successCaption!: string;
  currentPage = {name: this.category}

  constructor(
    private formBuilder: FormBuilder,
    private service: CustomerContactService,
    private companyContactService: CompanyContactService,
    private tokenStorage: TokenStorageService,
    private snackBar: MatSnackBar,
    private translate: TranslateService) {

      this.translate.get('save').subscribe((res: string) => { this.submitCaption = res; });
      this.translate.get('success').subscribe((res: string) => { this.successCaption = res; });
  }

  get f() { return this.itemForm.controls; }

  async ngOnInit() {
    this.orgId = 1;
    this.itemForm = this.formBuilder.group({
      name: [null, Validators.required],
      title: null,
      tel: [null, Validators.required],
      email: null,
      content: null
    });
    await this.initData();
  }

  async initData() {
    const ccs = await this.companyContactService.getByLang(this.translate.currentLang).toPromise();
    if (ccs && ccs.length > 0) {
      this.yitem = ccs[0];
    }
    const cr = this.tokenStorage.getObjectItem('customerRequest');
    if (cr && cr.name) {
      this.item = cr;
    } else {
      this.item = {orgId: this.orgId, status: 'New'} as CustomerContactInfo;
    }
    this.f.name.setValue(this.item.name);
    this.f.title.setValue(this.item.title);
    this.f.tel.setValue(this.item.tel);
    this.f.email.setValue(this.item.email);
    this.f.content.setValue(this.item.content);
  }

  async saveItem() {
    this.item.orgId = this.orgId;
    this.item.name = this.f.name.value;
    this.item.title = this.f.title.value;
    this.item.tel = this.f.tel.value;
    this.item.email = this.f.email.value;
    this.item.content = this.f.content.value;

    this.item = await this.service.upsert(this.item).toPromise();
    this.tokenStorage.saveObjectItem('customerRequest', this.item);

    this.snackBar.open(this.submitCaption + this.successCaption, undefined, {
      duration: 3000,
    });
  }

}
