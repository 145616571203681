import { NgModule } from '@angular/core';
import { ThemePickerComponent } from './theme-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YkaBaseCommonModule } from 'projects/yka-base-common/src/public-api';



@NgModule({
  declarations: [ThemePickerComponent],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatGridListModule,
    MatTooltipModule,
    YkaBaseCommonModule
  ],
  exports: [ThemePickerComponent]
})
export class ThemePickerModule { }
