import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AbstractHttpService } from './abstract-http.service';
import { BACKEND_URL } from '../helper/yk-token';
import { SystemDefaultInfo } from '../entity/system-default-info';

@Injectable({
  providedIn: 'root'
})
export class SystemDefaultService  extends AbstractHttpService<SystemDefaultInfo> {
  constructor(@Inject(BACKEND_URL) backendUrl: string, http: HttpClient) {
    super(backendUrl, http, 'systemdefault');
  }

  findByName(name: string) {
    return this.http.get<SystemDefaultInfo>(`${this.backendUrl}${this.pathVar}/byname/${name}`);
  }

}
