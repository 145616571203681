<div class="container nav-row">
  <div class="d-flex ">
    <mat-icon class="mr-1 nav-icon" svgIcon="home"></mat-icon>
    <div class="link-item" class="mr-1">{{'yourLocation' | translate}}: </div>
    <a class="link-item" [routerLink]="['/home']">{{'home' | translate}}</a>
    <mat-icon class="mx-1 nav-icon" svgIcon="chevron-right"></mat-icon>
    <div *ngIf="parent.name" class="d-flex">
      <a class="link-item mr-1" [routerLink]="['/list', linkMap.get(parent.id), parent.id, -1]">{{parent.name}}</a>
      <mat-icon class="mr-1 nav-icon" svgIcon="chevron-right"></mat-icon>
    </div>
    
    <ng-container *ngIf="page.id; else elseTemplate">
      <div *ngIf="!isLink" class="link-item" >{{page.name}}</div>
      <a *ngIf="isLink" class="link-item" [routerLink]="['/list', linkMap.get(parent.id), parent.id, page.id]" >{{page.name}}</a>
    </ng-container>
    <ng-template #elseTemplate>
      <div *ngIf="!page.id" class="link-item">{{page.name | translate}}</div>
    </ng-template>
    
  </div>
</div>
