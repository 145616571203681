import { IDisplayPreference } from './definitions';


export const ChinesePreference: IDisplayPreference = {
  hour:  (x) => x,
  minute: (x) => {
    let exp = x;
    if (exp.length === 1) {
      exp = '۰' + exp;
    }
    return exp;
  },
  separator: ':',
  period: (x) => x === 'AM' ? '上午' : '下午',
  clockHour: (x) => x,
  clockMinute: (x) => x,
  labels: {
    ok: '确定',
    cancel: '取消'
  }
};

export const EnPreference: IDisplayPreference = {
  hour:  (x) => x,
  minute: (x) => {
    let exp = x;
    if (exp.length === 1) {
      exp = '۰' + exp;
    }
    return exp;
  },
  separator: ':',
  period: (x) => x === 'AM' ? 'AM' : 'PM',
  clockHour: (x) => x,
  clockMinute: (x) => x,
  labels: {
    ok: 'OK',
    cancel: 'Cancel'
  }
};

export const Preference = (locale: string): IDisplayPreference => {
  switch (locale) {
    case 'zh':
        return ChinesePreference;
    default:
      return EnPreference;
  }
};
