import { group, query, trigger, transition, style, animateChild, animate, state, keyframes } from '@angular/animations';

export const slideInAnimation =
  trigger('routeAnimations', [
    transition('AllianceComponent => HomeComponent, MineComponent => HomeComponent, MineComponent => AllianceComponent',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('HomeComponent => AllianceComponent, HomeComponent => MineComponent, AllianceComponent => MineComponent', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ right: '-100%'})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ right: '100%'}))
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ right: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
  ]);

export const slideImage = trigger('imageMove', [
  // not display
  state('off', style({display: 'none', 'z-index': '0', transform: 'translateX(0)'})),
  // prevous image
  state('prev', style({'z-index': '1', transform: 'translateX(-100%)'})),
  // next image
  state('next', style({'z-index': '2', transform: 'translateX(100%)'})),
  // current image
  state('on', style({'z-index': '3', transform: 'translateX(0)'})),
  transition('prev=>on, next=>on, on=>prev, on=>next', [])
]);

export const flyInCart = trigger('flyincart', [
  // state('start', style({
  //     display: 'inline-block',
  //     width: '20px',
  //     height: '20px',
  //     borderRadius: '50%',
  //     background: 'blue',
  //     marginLeft: '100px',
  //     marginTop: '100px'
  //   })
  // ),
  // state('end', style({
  //     display: 'inline-block',
  //     width: '20px',
  //     height: '20px',
  //     borderRadius: '50%',
  //     background: 'blue',
  //     marginLeft: '100px',
  //     marginTop: '100px'
  //   })
  // ),
  // transition('start => end', [animate('0.5s', 
  //     style({transform: 'translateX(100px)'}))
  // ])
  state('open', style({
    // height: '200px',
    // opacity: 1,
    // backgroundColor: 'yellow',
    transform: 'translateX(100px)'
  })),
  state('closed', style({
    // left: '-100px',
    opacity: 0.5,
    backgroundColor: 'green'
  })),
  // transition('open => close', [
  //   animate('1s', style({transform: 'translateX(-100px)'}))
  // ]),
  transition('* => open', [
    group([
      // animate('0s', style({transform: 'translateX(-200px)'})),
      animate('1s cubic-bezier(.17,.67,.8,1.44)', style({transform: 'translateY(200px)'}))
    ])
    
  ]),

]);

export const countChange = trigger('count', [
  transition(':increment', [
    animate(
      '400ms 150ms',
      keyframes([
        style({ opacity: 0.4, transform: 'scale(1.5)', offset: 0 }),
        style({ opacity: 0.2, transform: 'scale(2)', offset: 0.5 }),
        style({ opacity: 0, transform: 'scale(1.5)', offset: 1 })
      ])
    )
  ]),
  transition(':decrement', [
    animate(
      '400ms',
      keyframes([
        style({ opacity: 0, transform: 'rotateZ(15deg)', offset: 0 }),
        style({ opacity: 0.3, transform: 'rotateZ(30deg)', offset: 0.5 }),
        style({ opacity: 0.6, transform: 'rotateZ(15deg)', offset: 1.0 })
      ])
    )
  ])
]);
