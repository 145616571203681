import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RecruitmentInfo } from '../entity';
import { AbstractHttpService } from 'projects/yka-base-common/src/public-api';
import { BACKEND_URL, PageInfo } from 'projects/yka-base-common/src/lib/helper';

@Injectable({
  providedIn: 'root'
})
export class RecruitmentService extends AbstractHttpService<RecruitmentInfo> {

  constructor(
    @Inject(BACKEND_URL) backendUrl: string,
    http: HttpClient
  ) {
    super(backendUrl, http, 'recruitment');
  }

  getByLang(lang: string) {
    return this.http.get<RecruitmentInfo[]>(`${this.backendUrl}${this.pathVar}/bylang/${lang}`);
  }

  getByLangPage(lang: string, pageInfo: PageInfo) {
    let params = new HttpParams();
    Object.keys(pageInfo).forEach(itm => {
      params = params.set(itm, pageInfo[itm as keyof PageInfo] + '');
    });

    return this.http.get<RecruitmentInfo[]>(`${this.backendUrl}${this.pathVar}/bylangpage/${lang}`, { params });
  }

}
