import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TokenStorageService } from 'projects/yka-base-common/src/lib/service/token-storage.service';

@Component({
  selector: 'app-nav-loc-bar',
  templateUrl: './nav-loc-bar.component.html',
  styleUrls: ['./nav-loc-bar.component.scss']
})
export class NavLocBarComponent implements OnInit {
  @Input() parent = {} as {id: number, name: string};
  @Input() page = {} as {id?: number, name: string};
  @Input() isLink = false;
  linkMap = new Map<number, string>();

  constructor(
    private tokenStorage: TokenStorageService
    ) { }

  ngOnInit(): void {
    const sm = this.tokenStorage.getItem('linkMap');
    this.linkMap = sm ? new Map(JSON.parse(sm)) : new Map<number, string>();
  }

}
