import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { GetNameByIdPipe } from './helper/custom-pipe.pipe';

import { PaginatorI18n } from './paginator-i18n'
import { YkaBaseCommonModule } from 'projects/yka-base-common/src/public-api';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { SpinnerComponent } from './common/page/spinner/spinner.component';
import { ScrollToTopComponent } from './component/scroll-to-top/scroll-to-top.component';
import { MatIconModule } from '@angular/material/icon';
import { FlootActionComponent } from './component/floot-action/floot-action.component';
import { MatButtonModule } from '@angular/material/button';
import { CheckMatchDirective } from './helper/check-match.directive';

@NgModule({
  declarations: [
    CheckMatchDirective,
    GetNameByIdPipe,
    // SpinnerComponent,
    ScrollToTopComponent,
    FlootActionComponent
  ],
  entryComponents: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    // MatProgressSpinnerModule,
    YkaBaseCommonModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    }
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    // MatProgressSpinnerModule,
    TranslateModule,
    YkaBaseCommonModule,
    CheckMatchDirective,
    GetNameByIdPipe,
    // SpinnerComponent,
    ScrollToTopComponent,
    FlootActionComponent
  ]
})
export class YkaCommonModule { }
