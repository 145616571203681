import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackHelperService {
  saveCaption: string = '';
  deleteCaption: string = '';
  successCaption: string = '';

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService) {
    this.translate.get('save').subscribe((res: string) => { this.saveCaption = res; });
    this.translate.get('delete').subscribe((res: string) => { this.deleteCaption = res; });
    this.translate.get('success').subscribe((res: string) => { this.successCaption = res; });
  }

  openSnack(action: string) {
    let msg = '';
    switch (action) {
      case 'save':
        msg = this.saveCaption + this.successCaption;
        break;
      case 'delete':
        msg = this.deleteCaption + this.successCaption;
        break;
      default:
        msg = this.translate.instant(action) + this.successCaption;
        break;
    }
    this.snackBar.open(msg);
  }

}
