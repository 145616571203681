import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {
  constructor(private readonly translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
      const paginatorIntl = new MatPaginatorIntl();
      this.translate.get('paginatorIntl').subscribe(res => {
        paginatorIntl.itemsPerPageLabel = res.itemsPerPageLabel;
        paginatorIntl.nextPageLabel = res.nextPageLabel;
        paginatorIntl.previousPageLabel = res.previousPageLabel;
        paginatorIntl.firstPageLabel = res.firstPageLabel;
        paginatorIntl.lastPageLabel = res.lastPageLabel;
        paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
      });
      return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
      if (length === 0 || pageSize === 0) {
          return this.translate.instant('paginatorIntl.rangePageLabel_1', { length });
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return this.translate.instant('paginatorIntl.rangePageLabel_2', { startIndex: startIndex + 1, endIndex, length });
  }}
