import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { TokenStorageService } from './token-storage.service';
import { UserInfo } from '../entity';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../helper/utils';
import { BACKEND_URL } from '../helper/yk-token';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
    isWeChat: boolean;

    grantedLinks!: string[];
    user!: UserInfo;

    constructor(
        @Inject(BACKEND_URL) private backendUrl: string,
        private http: HttpClient,
        private router: Router,
        private tokenStorage: TokenStorageService,
        private authenticationService: AuthenticationService
    ) {
        let authType = '';
        authType = this.tokenStorage.getItem('authType') || '';
        this.isWeChat = !authType && Utils.isWeChat();
        if (this.isWeChat) {
            return;
        }
        const links = this.tokenStorage.getObjectItem('grantedLinks');
        this.grantedLinks = links ? Array.from(links) : [];
        this.user = this.tokenStorage.getUser();
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // if (!this.isWeChat && this.user
        //     && this.user.username !== Utils.defaultAdmin && this.grantedLinks
        //     && this.grantedLinks.indexOf(state.url) < 0) {
        //     return false;
        // }
        if (state.url.includes('agreement')) {
            return true;
        }
        let token = route.queryParams.token;
        if (token || this.tokenStorage.getToken()) {
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        if (this.isWeChat) {
            this.tokenStorage.saveItem('nav', state.url);
            token = this.tokenStorage.getToken();
            const code = route.queryParams.code;
            const wxstate = route.queryParams.state;
            await this.authenticationService.wxLogin(code, wxstate, token, state.url);
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        }
        return false;
    }
}
