import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent, DialogData } from './confirm.component';

@Injectable({
  providedIn: 'root'
})
export class OpenDialogService {

  constructor(private dialog: MatDialog) { }

  async openConfirmDialog(data: DialogData): Promise<any> {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: data.maxWidth,
      width: data.width,
      data
    });

    const rlt = await dialogRef.afterClosed().toPromise();
    return rlt;
  }

}
