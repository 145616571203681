export class WxPayOrderInfo {
  constructor(
    public userId: number,
    public openId: string,
    public totalFee: number,
    public feeType: string,
    public outTradeNo: string,
    public body: string,
    public detail: string,
    public attach: string
  ) {}
}
