import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../service/alert.service';


@Component({
    selector: 'yka-base-common-alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription!: Subscription;
    message: any;

    constructor(private alertService: AlertService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.message = message;
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
