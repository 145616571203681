import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

export class ItemNode {
  parents?: any[];
  children?: ItemNode[];
  item: any;
}

export class Utils {
  static defaultAdmin = 'yk_cd';
  static Plaza = 'plaza';
  static Promotion = 'promotion';
  static Store = 'store';
  static smsCodeTimeout = 300; // seconds
  static fullVoltageMv = 48000; // mv
  static systemId = '';
  static mutlipleTenant = false;
  static isMiniProgram = false;
  static valueHolder = {} as any;
  static systemSettings: Map<string, any> = new Map<string, any>();

  static divide(n1: number, n2: number, point?: number) {
    if (n2 === 0) {
      return 0;
    }
    if (!point) {
      point = 2;
    }
    return Number((n1 / n2).toFixed(point));
  }

  static buildTree(items: any[]): ItemNode[] {
    const roots: ItemNode[] = [];
    let root = new ItemNode();
    const nodes = {} as any;
    for (const item of items) {
      const node = new ItemNode();
      node.item = item;
      node.children = [];
      node.parents = [];

      if (!item.parentId) {
        root = node;
        nodes[root.item.id] = node;
        roots.push(root);
      } else {
        node.parents = node.parents.concat(nodes[item.parentId].parents);
        node.parents.push(item.parentId);
        nodes[item.id] = node;
        nodes[item.parentId].children.push(nodes[item.id]);
      }
    }
    return roots;
  }

  static toFlatTree(target: any[], items: ItemNode[], level?: string) {
    const prefix = level === '' || !!level;
    for (const node of items) {
      const n = {...node};
      // not add level indicator
      // n.item.name = prefix ? level + node.item.name : node.item.name;
      n.children = undefined;
      target.push(n);
      if (node.children && node.children.length > 0) {
        this.toFlatTree(target, node.children, prefix ? level + '+-' : undefined);
      }    
    }
    return target;
  }

  static isFullScreen() {
    const rate = window.screen.height / window.screen.width;    
    let limit =  window.screen.height === window.screen.availHeight ? 1.8 : 1.65; // 临界判断值  
    return rate > limit;
  }

  static isMobile() {
    const re: RegExp = /Android|iPhone|iPad|iOS/i;
    return re.test(window.navigator.userAgent);
  }

  static isiOS() {
    const re: RegExp = /iPhone|iPad|iOS/i;
    return re.test(window.navigator.userAgent) && /Apple/.test(window.navigator.vendor);
  }

  static isWeChat() {
    const re: RegExp = /MicroMessenger/i;
    return re.test(window.navigator.userAgent);
  }

  // static getWeixinVersion() {
  //   // Positive Lookbehind: (?<=regex), this could cause page cannot be load on mobile browser
  //   const mr = /(?<=MicroMessenger\/)\d+\.?\d+/i.exec(window.navigator.userAgent);
  //   if (mr && mr.length > 0) {
  //     return Number(mr[0]);
  //   } else {
  //     return 0;
  //   }
  // }

  static removeElement(items: any[], item: any) {
    if (!items) {
      return items;
    }
    return items.filter(function (x) {
      return x != item;
    });
  }

  static removeElementByIdVal(items: any[], id: any) {
    if (!items) {
      return items;
    }
    return items.filter(function (x) {
      return x.id !== id;
    });
  }

  static removeElementById(items: any[], item: any) {
    if (!items) {
      return items;
    }
    return items.filter(function (x) {
      return x.id !== item.id;
    });
  }

  static getElementById(items: any[], id: any) {
    if (!items) {
      return null;
    }
    let rlt = items.filter(function (x) {
      return x.id == id;
    });
    if (rlt && rlt.length>0) {
      return rlt[0];
    } else {
      return null;
    }
  }

  static findElementBySpecificValue(items: any[], prop: string, value: any) {
    if (!items) {
      return null;
    }
    let rlt = items.filter(function (x) {
      return x[prop] == value;
    });
    if (rlt && rlt.length>0) {
      return rlt[0];
    } else {
      return null;
    }
  }

  static findElementBySpecific2Value(items: any[], prop1: string, value1: any, prop2: string, value2: any) {
    if (!items) {
      return null;
    }
    let rlt = items.filter(function (x) {
      return x[prop1] == value1 && x[prop2] == value2;
    });
    if (rlt && rlt.length>0) {
      return rlt[0];
    } else {
      return null;
    }
  }

  static findElementByNameContaining(items: any[], name: string) {
    if (!items) {
      return null;
    }
    let rlt = items.filter(function (x) {
      return x.name.includes(name);
    });
    return rlt;
  }

  static mapToObject(map: Map<string, any>) {
    if (map) {
      let obj = Object.create(null);
      map.forEach((v,k) => {
        obj[k] = v;
      });
      return obj;
    }
    return null;
  }

  static getDefaultPageSetting() {
    return {
      totalElements: 0,
      totalPages: 0,
      maxSize: 10,
      page: 1,
      size: 10,
      sort: 'id,desc'
    };
  }

  static async delay(ms: number) {
    const result = await new Promise( resolve => setTimeout(resolve, ms) );
    return result;
  }

  static html2text(src: string) {
    if (!src) {
      return src;
    }
    return src.replace(/<[^>]*>/g, '');
  }

  static getZone8DateString(d: Date) {
    if (typeof d === 'string') {
      return (d + '').substr(0, 10);
    } else {
      d = this.changeHours(d, 8);
      return d.toISOString().substr(0, 10);
    }
  }
  
  static getZone8DatetimeString(d: Date) {
    let s = '';
    if (typeof d === 'string') {
      s = (d + '').substr(0, 19);
    } else {
      d = this.changeHours(d, 8);
      s = d.toISOString().substr(0, 19);
    }
    return s.replace('T', ' ');
  }
  
  static getZoneDateString(d: Date, zone: number) {
    if (typeof d === 'string') {
      return (d + '').substr(0, 10);
    } else {
      d = this.changeHours(d, zone);
      return d.toISOString().substr(0, 10);
    }
  }
  
  static getZoneDatetimeString(d: Date, zone: number) {
    let s = '';
    if (typeof d === 'string') {
      s = (d + '').substr(0, 19);
    } else {
      d = this.changeHours(d, zone);
      s = d.toISOString().substr(0, 19);
    }
    return s.replace('T', ' ');
  }
  
  static changeHours(d: Date, hours: number) {
    const d1 = new Date(d);
    return new Date(d1.setHours(d.getHours() + hours));
  }
  
  static addDays(d: Date, days: number) {
    const d1 = new Date(d);
    return new Date(d1.setDate(d.getDate() + days));
  }

  static subtractDays(d: Date, days: number) {
    const d1 = new Date(d);
    return new Date(d1.setDate(d.getDate() - days));
  }

  static dbDateToString(dbDate: any, l: number) {
    const d = dbDate.substr(0, l);
    if (l > 10) {
      return d.replace('T', ' ');
    }
    return d;
  }

  static stringToDate(dateString: any) {
    if (dateString && dateString.length > 9) {
      const ds = dateString.substr(0, 10);
      const date = ds.split('-');

      return new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]));
    }
    return null;
  }

  static stringToDateTime(dateString: any) {
    if (dateString && dateString.length > 18) {
      const ds = dateString.substr(0, 20);
      const date = ds.split('T')[0].split('-');
      const time = ds.split('T')[1].split(':');

      return new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), Number(time[0]), Number(time[1]), Number(time[2]));
    }
    return null;
  }

  static calcDuration(begin: Date, end: Date) {
    let x = end.getTime() - begin.getTime();
    const d = Math.floor(x / 1000 / 60 / 60 / 24); // 天数
    let s = '';
    if (d > 0) {
      s = d + '天 ';
      x -= d * 1000 * 60 * 60 * 24;
    }
    const h = Math.floor(x / 1000 / 60 / 60); // 小时
    if (h > 0) {
      s += h + '小时 '
      x -= h * 1000 * 60 * 60;
    }
    const m = Math.floor(x / 1000 / 60); // 分钟
    s = s + m + '分钟';
    return s;
  }

  static getAge(birthday: Date) {
    const thisYear = new Date().getFullYear();
    return thisYear - new Date(birthday).getFullYear();
  }

  static secondsToMinSec(seconds: number) {
    return ((seconds - (seconds % 60)) / 60) + '\' ' + (seconds % 60) + '"';
  }

  static subStrFromLast(item: string, key: string) {
    return item ? item.substring(item.lastIndexOf(key)+1) : item;
  }

  static largeNumToAbbr(n: number) {
    if (!n || n == 0) {
      n = 1;
    }
    return n > 10000 ? Math.ceil(n / 1000) / 10 + ' 万' : n;
    //Intl.NumberFormat
  }

  static coordinateToStr(items: any[]) {
    let s = '';
    if (!items) {
      return s;
    }
    for (const itm of items) {
      s += itm.lat + ',' + itm.lng + ';';
    }
    return s;
  }

  static addBlack4(str?: string) {
    if (str && str.length > 0) {
      let s = str.trim();
      s = s.replace(/\s/g, '').replace(/(.{4})/g, "$1 ");
      return s.trim();
    } else {
      return str;
    }
  }

  static fileExists(httpClient: any, url: string): Observable<boolean> {
    return httpClient.get(url)
      .pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          return of(error.status === 200);
        })
      );
  }
  
}
