import { Injectable } from '@angular/core';
import { Utils } from '../helper';

const TOKEN_KEY = 'AuthToken';
const USER_KEY = 'AuthUser';
const USERNAME_KEY = 'AuthUsername';
const AUTHORITIES_KEY = 'AuthAuthorities';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private auths: Array<string> = [];
  private isMiniProgram = /miniProgram/i.test(navigator.userAgent);

  constructor() { }

  signOut() {
    if (this.isMiniProgram) {
      window.localStorage.clear();
    } else {
      window.sessionStorage.clear();
    }
  }

  removeObject(key: string) {
    key = Utils.systemId + key;
    if (this.isMiniProgram) {
      window.localStorage.removeItem(key);
    } else {
      window.sessionStorage.removeItem(key);
    }
  }

  saveItem(key: string, value: string) {
    key = Utils.systemId + key;
    if (this.isMiniProgram) {
      window.localStorage.setItem(key, value);
    } else {
      window.sessionStorage.setItem(key, value);
    }
  }

  getItem(key: string) {
    key = Utils.systemId + key;
    if (this.isMiniProgram) {
      return window.localStorage.getItem(key);
    } else {
      return window.sessionStorage.getItem(key);
    }
  }

  saveObjectItem(key: string, value: any) {
    this.saveItem(key, JSON.stringify(value));
  }

  getObjectItem(key: string) {
    let rlt = this.getItem(key);
    if (rlt !== 'null' && rlt !== 'undefined' && rlt && rlt.length > 0) {
      rlt = (rlt.startsWith("{") || rlt.startsWith("[")) ? JSON.parse(rlt) : rlt;
    } else {
      return null;
    }
    return rlt as any;
  }

  public saveToken(token: string) {
    this.removeObject(TOKEN_KEY);
    this.saveItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return this.getItem(TOKEN_KEY) || '';
  }

  public saveUsername(username: string) {
    this.removeObject(USERNAME_KEY);
    this.saveItem(USERNAME_KEY, username);
  }

  public getUsername(): string {
    return this.getItem(USERNAME_KEY) || '';
  }

  public saveUser(user: any) {
    this.saveItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return this.getObjectItem(USER_KEY);
  }

  public saveAuthorities(authorities: string[]) {
    this.removeObject(AUTHORITIES_KEY);
    this.saveItem(AUTHORITIES_KEY, JSON.stringify(authorities));
  }

  public getAuthorities(): string[] {
    this.auths = [];
    const ak = this.getObjectItem(AUTHORITIES_KEY);
    if (this.getItem(TOKEN_KEY) && ak) {
      if (Array.isArray(ak)) {
        ak.forEach((authority: any) => {
          this.auths.push(authority.authority);
        });
      }
    }

    return this.auths;
  }
}
