import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BACKEND_URL } from '../helper';

@Injectable({
  providedIn: 'root'
})
export class WxUtilService {
  pathVar = 'wxminiutil';

  constructor(
    @Inject(BACKEND_URL) private backendUrl: string,
    private http: HttpClient,) {
  }

  getUnLimitedQr(param: any) {
    return this.http.post<string>(`${this.backendUrl}${this.pathVar}/ulqr`, param, {responseType: 'text' as 'json'});
  }

}
