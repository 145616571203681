import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SpecialLink } from 'projects/share-lib/src/lib/entity';
import { AdImage } from 'projects/yka-base-common/src/lib/entity';
import { Utils } from 'projects/yka-base-common/src/lib/helper';
import { TokenStorageService } from 'projects/yka-base-common/src/lib/service/token-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  
  @Input() location!: string;
  @Input() colors!: any;
  @Input() logo: string = '';
  @Input() builtinContactUs: boolean = true;
  @Input() hitems: {id: number, parent: number, name: string}[][] = [];
  @Input() imgs: AdImage[] = [];
  @Input() linkMap!: Map<number, string>;
  ratio: string = '20vw';
  ratioOther: string = '15vw';
  showHeaderImage!: string;

  specialLinks: SpecialLink[] = [];

  constructor(
    private tokenStorage: TokenStorageService,
    private cdr: ChangeDetectorRef
  ) {
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.linkMap) {
      this.cdr.detectChanges();
    }
  }

  async ngOnInit(): Promise<void> {
    let i = 0;
    this.showHeaderImage = this.tokenStorage.getItem('showHeaderImage');
    this.ratio = this.tokenStorage.getItem('headerImageRatio');
    while (!this.ratio && i < 10) {
      i = i + 1;
      await Utils.delay(100);
      this.ratio = this.tokenStorage.getItem('headerImageRatio');
      this.ratioOther = this.tokenStorage.getItem('headerImageRatioOther');
    }
    
    this.builtinContactUs = this.tokenStorage.getObjectItem('builtinContactUs') === '1';
    this.logo = this.tokenStorage.getObjectItem('logo');
    this.hitems = this.tokenStorage.getObjectItem('hitems') || [];
    this.imgs = this.tokenStorage.getObjectItem('imgs') || [];
    const sm = this.tokenStorage.getItem('linkMap');
    this.linkMap = sm ? new Map(JSON.parse(sm)) : new Map<number, string>();
    const topMenu = this.tokenStorage.getObjectItem('topMenu');
    if (topMenu) {
      document.documentElement.style.setProperty('--font-color', topMenu.fontColor);
      document.documentElement.style.setProperty('--bg-color', topMenu.bgColor);
      document.documentElement.style.setProperty('--a-color', topMenu.linkColor);
      document.documentElement.style.setProperty('--hover-bg-color', topMenu.hoverBgColor);
      document.documentElement.style.setProperty('--hover-color', topMenu.hoverColor);
      this.specialLinks = topMenu.specialLinks;
    }
  }

}
