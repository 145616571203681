import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageCategoryInfo, WebpageInfo } from 'projects/share-lib/src/lib/entity';
import { PageCategoryService, WebpageService } from 'projects/share-lib/src/lib/service';
import { BACKEND_URL, TokenStorageService } from 'projects/yka-base-common/src/public-api';

@Component({
  selector: 'app-webpage',
  templateUrl: './webpage.component.html',
  styleUrls: ['./webpage.component.scss']
})
export class WebpageComponent implements OnInit {

  id: number = -1;

  parent = {} as PageCategoryInfo;
  category = {} as PageCategoryInfo;
  item: WebpageInfo = {} as WebpageInfo;
  showTitleImage: boolean = true;

  constructor(
    private route: ActivatedRoute,
    @Inject(BACKEND_URL) public backendUrl: string,
    private tokenStorage: TokenStorageService,
    private translate: TranslateService,
    private pcService: PageCategoryService,
    private service: WebpageService
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async param => {
      this.id = Number(param.get('id'));
      await this.getItem();
      // this.checkShowTitleImage(this.id);
    });
  }

  // checkShowTitleImage(category: number) {
  //   const sm = this.tokenStorage.getItem('linkMap');
  //   const linkMap = sm ? new Map(JSON.parse(sm)) : new Map<number, string>();
  //   this.showTitleImage = linkMap.get(category) !== 'layout-4';
  // }

  async getItem() {
    this.item = await this.service.getById(this.id).toPromise();
    // this.item.createdTime = this.item.createdTime.replace('T', ' ').substr(0, 19);
    this.category = await this.pcService.getById(this.item.category).toPromise();
    if (this.category.parent) {
      this.parent = await this.pcService.getById(this.category.parent).toPromise();
    } else {
      this.parent.id = this.category.id;
    }
  }
  
}
