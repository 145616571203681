import { HttpClient, HttpParams } from '@angular/common/http';
import { Utils } from '../helper/utils';
import { PageInfo } from '../helper/page-info';

// Make sure than you do not have this @Injectable() tag in any of your super classes, as you cannot inject a Super class.
// @Injectable({
//   providedIn: 'root'
// })
export class AbstractHttpService<T> {

  constructor(
    protected backendUrl: string,
    protected http: HttpClient,
    protected pathVar: string) { }

  upsert(ro: T) {
    return this.http.post<any>(`${this.backendUrl}${this.pathVar}`, ro);
  }

  update(id: number, fields: Map<string, any>) {
    const f = Utils.mapToObject(fields);
    return this.http.patch<boolean>(`${this.backendUrl}${this.pathVar}/${id}`, f);
  }

  getAll() {
    return this.http.get<T[]>(`${this.backendUrl}${this.pathVar}`);
  }

  queryWithPage(pageInfo: PageInfo) {
    let params = new HttpParams();
    Object.keys(pageInfo).forEach(itm => {
      params = params.set(itm, pageInfo[itm as keyof PageInfo] + '');
    });

    return this.http.get<any>(`${this.backendUrl}${this.pathVar}/pageable`, { params });
  }

  queryWithExamplePage(ro: T, pageInfo: PageInfo, ...ignoreProps: string[]) {
    let params = new HttpParams();
    Object.keys(pageInfo).forEach(itm => {
      params = params.set(itm, pageInfo[itm as keyof PageInfo] + '');
    });
    if (ignoreProps) {
      for (const p of ignoreProps) {
        params = params.append('ignoreProps', p);
      }
    }
    return this.http.post<any>(`${this.backendUrl}${this.pathVar}/byexamplepage`, ro, { params });
  }

  queryWithExampleLikePage(ro: T, pageInfo: PageInfo, ...ignoreProps: string[]) {
    let params = new HttpParams();
    Object.keys(pageInfo).forEach(itm => {
      params = params.set(itm, pageInfo[itm as keyof PageInfo] + '');
    });
    if (ignoreProps) {
      for (const p of ignoreProps) {
        params = params.append('ignoreProps', p);
      }
    }

    return this.http.post<any>(`${this.backendUrl}${this.pathVar}/byexamplelikepage`, ro, { params });
  }

  getById(id: number) {
    return this.http.get<T>(`${this.backendUrl}${this.pathVar}/${id}`);
  }

  delete(id: number) {
    return this.http.delete(`${this.backendUrl}${this.pathVar}/${id}`);
  }

  deleteAll() {
    return this.http.delete(`${this.backendUrl}${this.pathVar}`);
  }

  batchDelete(ros: T[]) {
    return this.http.post(`${this.backendUrl}${this.pathVar}/deleteall`, ros);
  }

  findByIdIn(ids: number[]) {
    return this.http.post<T[]>(`${this.backendUrl}${this.pathVar}/byidin`, ids);
  }

  findByExample(ro: T, ...ignoreProps: string[]) {
    return this.http.post<T[]>(`${this.backendUrl}${this.pathVar}/byexample`, ro, {params: {ignoreProps}});
  }

  findByExampleLike(ro: T, ...ignoreProps: string[]) {
    return this.http.post<T[]>(`${this.backendUrl}${this.pathVar}/byexamplelike`, ro, {params: {ignoreProps}});
  }

  findByName(name: string) {
    return {} as any;
  }
}
