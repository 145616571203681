<app-header></app-header>

<app-nav-loc-bar [parent]="parent" [page]="category" [isLink]="true"></app-nav-loc-bar>

<div class="row container content-row">
  <div class="col">
    <div class="row">
      <div class="col d-flex justify-content-center">
        <div class="elm-title">{{item.title}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <!-- <div class="content border-top" [innerHTML]="item.description"></div> -->
        <div class="content border-top">
          <yka-common-rich-editor [value]="item?.description" [disabled]="true"></yka-common-rich-editor>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-right">
        <div class="d-flex elm-subtitle">
          <div class="mr-2 sub-title-label">{{'addTime' | translate}}</div>
          <div class="mr-3">{{item.createdTime | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
          <div class="mr-2 sub-title-label">{{'recruitNum' | translate}}</div>
          <div class="mr-3">{{item.num}}</div>
          <div class="mr-2 sub-title-label">{{'recruiter' | translate}}</div>
          <div class="mr-3">{{item.recruiter}}</div>
          <div class="mr-2 sub-title-label">{{'tel' | translate}}</div>
          <a class="mr-3" href="tel:{{item.tel}}">{{item.tel}}</a>
          <div class="mr-2 sub-title-label">{{'email' | translate}}</div>
          <a href="mailto:{{item.email}}">{{item.email}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>