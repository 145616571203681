
<app-header [location]="'home'" [logo]="logo" [hitems]="hitems" [imgs]="imgs" [linkMap]="linkMap"></app-header>

<div *ngFor="let elm of elms; let i=index;" class="" [ngClass]="i % 2 === 0 ? 'primary-bg' : 'secondary-bg'">
  <div *ngIf="elm.layout !== 'layout-4'" class="row container content-row">
    <div class="col">
      <div class="row">
        <div class="col category">{{elm.categoryName}}</div>
      </div>
      <div class="row">
        <div *ngIf="elm.layout === 'layout-1'" class="d-flex">
          <div *ngIf="idx > 0" class="tile-box-left" (click)="idx = idx - 1;">
            <div class=" tile-btn-left"></div>
          </div>
          
          <div *ngFor="let itm of tiles" class="col-sm-12 col-md-6 col-lg-4 d-flex flex-column cursor-pointer" [routerLink]="['/webpage', elm.pages[idx + itm]?.id]">
            <div *ngIf="elm.pages[idx + itm] && elm.pages[idx + itm].images" class="box">
              <div class="box-content" [style.background-image]="'url(' + backendUrl + elm.pages[idx + itm].images[0] + ')'">
              </div>
            </div>
            <!-- <img class="img-ly" *ngIf="elm.pages[idx + itm] && elm.pages[idx + itm].images" [src]="backendUrl + elm.pages[idx + itm].images[0]"> -->
            <div *ngIf="elm.pages[idx + itm]" class="d-flex flex-column mx-1">
              <div class="elm-title">{{elm.pages[idx + itm].title}}</div>
              <div class="elm-content" [ngClass]="i % 2 === 0 ? 'light-content-color' : 'gray-content-color'">
                {{elm.pages[idx + itm].content ? elm.pages[idx + itm].content : h2t(elm.pages[idx + itm].src)}}
              </div>
            </div>
          </div>
          <div *ngIf="idx < elm.pages.length - tiles.length" class="tile-box-right" (click)="idx = idx + 1;">
            <div class=" tile-btn-right"></div>
          </div>
        </div>
        <div *ngIf="elm.layout === 'layout-2'" class="col">
          <div *ngFor="let item of elm.pages" class="row mb-4">
            <div class="col-lg-7 d-flex flex-column">
              <a class="elm-title-ly2" [routerLink]="['/webpage', item.id]">{{item.title}}</a>
              <div class="elm-content-ly2" [ngClass]="i % 2 === 0 ? 'light-content-color' : 'gray-content-color'">{{item.content ? item.content : h2t(item.src)}}</div>
              <a class="mt-auto" [routerLink]="['/webpage', item.id]" >{{'visitMore' | translate}}</a>
            </div>
            <div *ngIf="item.images" class="col-lg-5">
              <img class="img-ly" [src]="backendUrl + item.images[0]">
            </div>
          </div>
        </div>
        <div *ngIf="elm.layout === 'layout-3'" class="col">
          <div *ngIf="elm.pages && elm.pages.length > 0" class="row">
            <div class="col-lg-5 d-flex flex-column">
              
              <ng-container *ngIf="elm.pages[0].images && elm.pages[0].images.length > 0; else elseTemplate">
                <img class="img-ly" [src]="backendUrl + elm.pages[0].images[0]">
              </ng-container>
              <ng-template #elseTemplate>
                <img class="img-ly" *ngIf="newsDefault.length > 0" [src]="newsDefault">
              </ng-template>
              
              <div class="d-flex flex-column">
                <a class="elm-title" [routerLink]="['/webpage', elm.pages[0].id]">{{elm.pages[0].title}}</a>
                <div class="elm-content" [ngClass]="i % 2 === 0 ? 'light-content-color' : 'gray-content-color'">
                  {{elm.pages[0].content ? elm.pages[0].content : h2t(elm.pages[0].src)}}
                </div>
              </div>
              <a class="mt-auto" [routerLink]="['/webpage', elm.pages[0].id]">{{'visitMore' | translate}}</a>
            </div>
            <div *ngIf="elm.pages.length > 1" class="col-lg-7">
              <div *ngFor="let item of elm.pages; let i=index">
                <div *ngIf="i > 0" class="d-flex flex-column pb-3 border-bottom">
                  <a class="elm-title" [routerLink]="['/webpage', elm.pages[i].id]">{{item.title}}</a>
                  <div class="elm-content" [ngClass]="i % 2 === 0 ? 'light-content-color' : 'gray-content-color'">{{item.content ? item.content : h2t(item.src)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="fastlinks.get(elm.category)" class="row d-flex justify-content-around ">
        <div *ngFor="let item of fastlinks.get(elm.category)" class="mt-5">
          <a class="rounded-pill fast-link" [ngStyle]="{ color: item.color, backgroundColor: item.backgroundColor }" 
            [href]="item.link" target="_blank" rel="noopener noreferrer">
            {{item.caption}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="elm.layout === 'layout-4'" class="">
      <div *ngFor="let item of elm.pages" class="" [routerLink]="item.src && item.src.length > 0 ? ['/simple-page', item.id] : null">
        <img class="img-ly-full" [src]="backendUrl + item.images[0]">
      </div>
  </div>
</div>

<app-footer [friendLinks]="fls" [icps]="icps"></app-footer>
