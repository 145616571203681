import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getOrientation } from './ImageUtils';
import { ImageCropService } from '../image-crop/image-crop.service';
import { FileHolder } from './file-holder';

@Injectable()
export class ImageUploadService {
  constructor(private http: HttpClient, private cropService: ImageCropService) {
  }

  public async uploadImage(
      url: string,
      fileHolder: FileHolder,
      crop?: boolean,
      ratio?: number,
      rotate?: boolean,
      compress?: boolean,
      headers?: HttpHeaders | { [name: string]: string | string[] },
      partName: string = 'image',
      customFormData?: { [header: string]: string | Blob },
      withCredentials?: boolean): Promise<Observable<HttpResponse<any>>> {
    if (!url || url === '') {
      throw new Error('Url is not set! Please set it before doing queries');
    }

    const formData = new FormData();

    if (customFormData) {
      for (const key of Object.keys(customFormData)) {
        formData.append(key, customFormData[key]);
      }
    }

    let image: any;
    const rFilter = /^(image\/jpeg|image\/png|image\/gif|image\/bmp)$/i;
    // > 1 MB
    if (rFilter.test(fileHolder.file.type)) {
      let ort = 0;
      // get orientation
      if (rotate) {
        getOrientation(fileHolder.src, d => { ort = d; });
      }
      if (crop) {
        const data = {
          imageSrc: fileHolder.src,
          config: {
            ratio,
            orient: ort,
            type: fileHolder.file.type
          }
        };
        fileHolder.src = await this.cropService.openCropDialog(data);
        // orientation should be corrected in cropping
        ort = 1;
      }
      if (compress) {
        image = await this.compress(fileHolder, ort);
        url += '/base64';
      } else {
        image = fileHolder.file;
      }
    } else {
      image = fileHolder.file;
    }
    formData.append(partName, image);
    return this.http.post(url, formData, { withCredentials, headers, observe: 'response' });
  }

  private compress(fileHolder: FileHolder, orient: number) {
    const largeFile = 102400; // 100 KB
    const largerFile = 512000; // 500 KB
    const xlargerFile = 1048576; // 1 MB
    const xxlargerFile = 2097152; // 2 MB
    return new Promise((resolve, reject) => {
      const cvs: HTMLCanvasElement = document.createElement('canvas');
      const ctx = cvs.getContext('2d');
      if (ctx) {
        let scale = 1;
        if (fileHolder.src.length > largeFile) {
          scale = fileHolder.src.length > largerFile ? .6 : .8;
        } else if (fileHolder.src.length > xlargerFile) {
          scale = fileHolder.src.length > xxlargerFile ? .25 : .5;
        }
        const ort = orient;
        const img = new Image();
        img.src = fileHolder.src;
        img.onload = () => {
          const w = img.naturalWidth * scale;
          const h = img.naturalHeight * scale;
          // set proper canvas dimensions before transform & export
          if (4 < ort && ort < 9) {
            cvs.width = h;
            cvs.height = w;
          } else {
            cvs.width = w;
            cvs.height = h;
          }
          // transform context before drawing image
          switch (ort) {
            case 2: ctx.transform(-1, 0, 0, 1, w, 0); break;
            case 3: ctx.transform(-1, 0, 0, -1, w, h); break;
            case 4: ctx.transform(1, 0, 0, -1, 0, h); break;
            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: ctx.transform(0, 1, -1, 0, h, 0); break;
            case 7: ctx.transform(0, -1, -1, 0, h, w); break;
            case 8: ctx.transform(0, -1, 1, 0, 0, w); break;
            default: break;
          }

          ctx.drawImage(img, 0, 0, w, h);
          const newImgData = cvs.toDataURL(fileHolder.file.type, scale);
          fileHolder.src = newImgData;
          resolve(newImgData);
        };
        img.onerror = reject;
      }
    });
  }

}
