import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from '../lib/material.module';
import { AdBoardModule, BACKEND_URL, IMAGE_URL, YkaBaseCommonModule } from 'projects/yka-base-common/src/public-api';
import { HomeComponent } from './main/page/home/home.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { FooterComponent } from './main/component/footer/footer.component';
import { ContactUsComponent } from './main/page/contact-us/contact-us.component';
import { HeaderComponent } from './main/component/header/header.component';
import { NavLocBarComponent } from './main/component/nav-loc-bar/nav-loc-bar.component';
import { NewsPageComponent } from './main/page/news-page/news-page.component';
import { WebpageComponent } from './main/page/webpage/webpage.component';
import { RecruitmentComponent } from './main/page/recruitment/recruitment.component';
import { SimplePageComponent } from './main/page/simple-page/simple-page.component';
import { RichEditorModule } from 'projects/yka-common/src/public-api';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    ContactUsComponent,
    HeaderComponent,
    NavLocBarComponent,
    NewsPageComponent,
    WebpageComponent,
    RecruitmentComponent,
    SimplePageComponent
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    YkaBaseCommonModule,
    RichEditorModule,
    AdBoardModule,
  ],
  providers: [

    // httpInterceptorProviders,
    { provide: BACKEND_URL, useValue: environment.backendUrl },
    { provide: IMAGE_URL, useValue: environment.imageUrl },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
