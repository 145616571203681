import { Injectable, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { BACKEND_URL, IMAGE_URL } from 'projects/yka-base-common/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  /**
   * new uploaded files, not include retrieved from DB. relative path
   */
  uploadedFiles: string[] = [];
  /**
   * all files, relative path, for saving to DB
   */
  savableFiles: string[] = [];
  /**
   * all files, full http path, for displaying
   */
  retrievableFiles: string[] = [];
  customFormData!: { [name: string]: string };

  constructor(
    private http: HttpClient,
    @Inject(BACKEND_URL) private backendUrl: string,
    @Inject(IMAGE_URL) public imageUrl: string,
    ) { }

  private getUploadFileName(event: any): string {
    let filename = '';
    if (event.serverResponse && event.serverResponse.status === 200) {
      filename = event.serverResponse.response.body.content;
    } else {
      filename = event.file.name;
    }
    return filename;
  }

  private removeFile(relativeFilename: string) {
    if (!relativeFilename || relativeFilename === '') {
      return;
    }

    this.uploadedFiles = this.uploadedFiles.filter(file => file !== relativeFilename);
    
    relativeFilename = relativeFilename.replace(this.imageUrl , '');
    this.savableFiles = this.savableFiles.filter(file => file !== relativeFilename);

    relativeFilename = this.imageUrl  + relativeFilename;
    this.retrievableFiles = this.retrievableFiles.filter(file => file !== relativeFilename);

    let param = new HttpParams();
    if (this.customFormData) {
      for (const key of Object.keys(this.customFormData)) {
        param = param.append(key, this.customFormData[key]);
      }
    }
    const filename = relativeFilename.substring(relativeFilename.lastIndexOf('/') + 1);

    this.http.post(`${this.backendUrl}file/delete/${filename}`, param).subscribe(
      res => {
      },
      error => {
      }
    );
  }

  init() {
    this.uploadedFiles = [];
    this.retrievableFiles = [];
    this.savableFiles = [];
  }

  fileRemoved(event: any) {
    const filename = this.getUploadFileName(event);
    this.removeFile(filename);
  }

  fileUploadFinished(event: any) {
    if (event.serverResponse.status === 200) {
      const filename = event.serverResponse.response.body.content;
      this.uploadedFiles.push(filename);
      this.savableFiles.push(filename);
      const rf = this.imageUrl  + filename;
      this.retrievableFiles.push(rf);
    }
  }

  retrieveFiles(files: string | string[]) {
    this.retrievableFiles = [];
    this.savableFiles = [];
    if (files) {
      if (!Array.isArray(files)) {
        files = [files];
      }
      if (files && files.length > 0) {
        for(let i = 0; i < files.length; i++) {
          let filename = files[i];
          this.savableFiles.push(filename);
          if (filename.indexOf(this.imageUrl) < 0) {
            filename = this.imageUrl  + filename;
          }
          this.retrievableFiles.push(filename);
        }
      }
    }

    return this.retrievableFiles;
  }

  removeFiles(files: string | string[]) {
    if (!Array.isArray(files)) {
      files = [files];
    }
    files.forEach(itm  => {
      this.removeFile(itm);
    });
  }

  removeFileAbs(file: string) {
    let body = new HttpParams();
    body = body.set('filename', file);

    return this.http.post<any>(`${this.backendUrl}file/deleteabs`, body);
  }

  // delete uploaded file from rich editor string
  deleteFileFromString(uploadUrl: string, src: string) {
    const re = new RegExp('(?<=(<figure class="image.*?"><img src=")).+?(?="><\/figure>)', 'g');
    const rlt = src.match(re);

    if (rlt) {
      this.http.post(`${uploadUrl}/deletehttp`, rlt).subscribe(
        res => {},
        error => {}
      );
    }

  }
}
