import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdBoardComponent } from './ad-board.component';



@NgModule({
  declarations: [AdBoardComponent],
  imports: [
    CommonModule
  ],
  exports: [AdBoardComponent]
})
export class AdBoardModule { }
