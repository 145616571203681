import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BACKEND_URL } from '../helper/yk-token';

@Injectable({
  providedIn: 'root'
})
export class WxService {
  pathVar = 'weixin';
  wx!: any;
  recordId!: string;

  constructor(
    @Inject(BACKEND_URL) private backendUrl: string,
    private http: HttpClient
  ) {
    this.wx = (window as any)['wx'];
  }

  wxConfig(url: string) {
    // miniProgram no need to config
    // if (Utils.isMiniProgram) return;
    const that = this;
    
    that.getConfigOA(url).subscribe(res => {
      if (!res || res.length === 0) return;
      that.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.appId, // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature:  res.signature, // 必填，签名
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareAppMessage',
          'onMenuShareTimeline',
          'scanQRCode',
          'getLocation',
          'openLocation'
          // 'startRecord',
          // 'stopRecord',
          // 'onVoiceRecordEnd',
          // 'playVoice',
          // 'uploadVoice'
        ]
      });
    });
  }

  wxShareToFriend() {
    // this.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      this.wx.updateAppMessageShareData({ 
        title: 'test-hare', // 分享标题
        desc: 'share-desc', // 分享描述
        link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'friend', // 分享图标
        success: function () {
          alert('success'); // 设置成功
        }
      });
    // });
  }
  wxShareToFriendOld() {
    this.wx.onMenuShareAppMessage({
      title: 'title-test', // 分享标题
      desc: 'share-desc', // 分享描述
      link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: 'ff', // 分享图标
      type: '', // 分享类型,music、video或link，不填默认为link
      dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
      success: function () {
        alert('success'); // 用户点击了分享后执行的回调函数
      }
    });
  }

  scanQrcode(fn: any) {
    // this.wxConfig(this.url);
    this.wx.scanQRCode({
      needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: fn
    });
  }

  getLocation() {
    return new Promise<any>((resolve, reject) => {
      this.wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: function (res: any) {
          resolve(res);
          var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          var speed = res.speed; // 速度，以米/每秒计
          var accuracy = res.accuracy; // 位置精度
        },
        fail: function(res: any) {
          console.log(res);
          reject(res);
        }
      });
    });
    
  }

  openLocation(latitude: number, longitude: number, name: string, address: string) {
    this.wx.openLocation({
      latitude, // 纬度，浮点数，范围为90 ~ -90
      longitude, // 经度，浮点数，范围为180 ~ -180。
      name, // 位置名
      address, // 地址详情说明
      scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
      infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
    });    
  }

  getRecorder() {
      this.wx.ready(() => {
        // 
        console.log(this.wx);
        this.wx.onVoiceRecordEnd({
          // 录音时间超过一分钟没有停止的时候会执行 complete 回调
            complete: function (res1: any) {
            this.recordId = res1.localId;
          }
          });
        });
  }

  startRecord() {
    this.wx.startRecord();
  }

  stopRecord() {
    const that = this;
    this.wx.stopRecord({
      success: function (res: any) {
        that.recordId = res.localId;
      }
    });
  }

  play() {
    this.wx.playVoice({
      localId: this.recordId // 需要播放的音频的本地ID，由stopRecord接口获得
    });
  }

  pause() {
    this.wx.pauseVoice({
      localId: this.recordId // 需要播放的音频的本地ID，由stopRecord接口获得
    });
  }

  stop() {
    this.wx.stopVoice({
      localId: this.recordId // 需要播放的音频的本地ID，由stopRecord接口获得
    });
  }

  getConfig(url: string) {
    let d = (new Date()).getTime() + '';
    // d = d.substr(0, 10);
    return this.http.post<any>(`${this.backendUrl}${this.pathVar}/config`, {url, timestamp: d});
  }

  getConfigOA(url: string) {
    let d = (new Date()).getTime() + '';
    // d = d.substr(0, 10);
    return this.http.post<any>(`${this.backendUrl}${this.pathVar}/configoa`, {url, timestamp: d});
  }

  sendTempMessage(msg: any) {
    return this.http.post<any>(`${this.backendUrl}${this.pathVar}/template`, msg);
  }

}
