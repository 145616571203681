import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, HostBinding } from '@angular/core';
import { FileHolder } from '../file-holder';
import { ImageUploadService } from '../image-upload.service';
import { Style } from '../style';
import { UploadMetadata } from '../upload-metadata';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'yka-common-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnChanges {
  isInvalidExtension = false;

  files: FileHolder[] = [];
  fileCounter = 0;
  fileOver = false;
  showFileTooLargeMessage = false;

  @Input() backgroundColor!: string;
  @Input() isVideo = false;
  @Input() isAllFileType = false;
  @Input() onlyIcon = false;
  @Input() crop = false;
  @Input()
  ratio!: number;
  @Input()
  rotate!: boolean;
  @Input() iconColor = '#000000';
  @Input()
  imageWidth!: string;
  @Input()
  imageHeight!: string;
  @Input() imageLineHeight = 'inherit';
  @Input()
  customFormData?: { [name: string]: string | Blob; };
  @Input() compress = false;
  @Input() buttonCaption = 'Select Images';
  @Input() disabled = false;
  @Input('class') cssClass = 'img-ul';
  @Input() clearButtonCaption = 'Clear';
  @Input() dropBoxMessage = 'Drop your images here!';
  @Input()
  fileTooLargeMessage!: string;
  @Input() mustMatchExtension = false;
  @Input() invalidExtensionMessage = '';
  @Input()
  headers!: HttpHeaders | { [name: string]: string | string[]; };
  @Input() max = 100;
  @Input()
  maxFileSize!: number;
  @Input() preview = true;
  @Input()
  partName!: string;
  @Input()
  style!: Style;
  @Input('extensions')
  supportedExtensions!: string;
  @Input()
  url!: string;
  @Input() withCredentials = false;
  @Input() uploadedFiles: string[] | Array<{ url: string, fileName: string, blob?: Blob }> = [];

  @Output() removed = new EventEmitter<FileHolder>();
  @Output() uploadStateChanged = new EventEmitter<boolean>();
  @Output() uploadFinished = new EventEmitter<FileHolder>();
  @Output() previewClicked = new EventEmitter<FileHolder>();

  @ViewChild('input', { static: false })
  private inputElement!: ElementRef;
  private pendingFilesCounter = 0;

  @Input() beforeUpload: (metadata: UploadMetadata) => UploadMetadata | Promise<UploadMetadata> = metadata => metadata;

  @HostBinding('attr.style')
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--image-width: ${this.imageWidth}`);
  }

  videoHeight!: string;

  constructor(
    private imageService: ImageUploadService,
    private sanitizer: DomSanitizer
    ) {
      // this.imageWidth = this.isAllFileType ? 'inherit' : '9rem';
      // this.imageHeight = this.isAllFileType ? 'inherit' : '9rem';
  }

  ngOnInit() {
    if (!this.fileTooLargeMessage) {
      this.fileTooLargeMessage = 'An image was too large and was not uploaded.' +
      (this.maxFileSize ? (' The maximum file size is ' + this.maxFileSize / 1024) + 'KiB.' : '');
    }
    // this.supportedExtensions = this.supportedExtensions ? this.supportedExtensions.map((ext) => 'image/' + ext) : ['image/*'];
    if (!this.imageLineHeight) {
      this.imageLineHeight = this.imageHeight;
    }
    if (!this.rotate) {
      this.rotate = /iPhone|iPad|iOS/i.test(window.navigator.userAgent);
    }
    if (this.isVideo && this.imageHeight) {
      const sn = /\d+/.exec(this.imageHeight);
      const ss = /\D+/.exec(this.imageHeight);
      if (sn && ss) {
        const n = Number(sn[0]);
        const s = ss[0];
        this.videoHeight = (s === 'rem' ? (n - 1) : (n - 16)) + s;
      }
    }
  }

  deleteAll() {
    this.files.forEach(f => this.removed.emit(f));
    this.files = [];
    this.fileCounter = 0;
    if (this.inputElement) {
      this.inputElement.nativeElement.value = '';
    }
  }

  deleteFile(file: FileHolder): void {
    if (this.disabled) {
      return;
    }
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.fileCounter--;
    if (this.inputElement) {
      this.inputElement.nativeElement.value = '';
    }
    this.removed.emit(file);
  }

  previewFileClicked(file: FileHolder) {
    this.previewClicked.emit(file);
  }

  ngOnChanges(changes: any) {
    this.files = [];
    this.fileCounter = 0;
    if (changes.uploadedFiles && changes.uploadedFiles.currentValue &&
      changes.uploadedFiles.currentValue.length > 0) {
      this.fileCounter = changes.uploadedFiles.currentValue.length;
      this.processUploadedFiles();
    }
  }

  onFileChange(files: FileList | undefined | null) {
    if (files) {
      if (this.disabled) { return; }
      this.isInvalidExtension = false;
      const remainingSlots = this.max - this.fileCounter;
      const filesToUploadNum = files.length > remainingSlots ? remainingSlots : files.length;

      if (this.url && filesToUploadNum !== 0) {
        this.uploadStateChanged.emit(true);
      }

      this.fileCounter += filesToUploadNum;
      this.showFileTooLargeMessage = false;
      this.uploadFiles(files, filesToUploadNum);
    }
  }

  onFileOver = (isOver: any) => this.fileOver = isOver;

  private onResponse(response: HttpResponse<any>, fileHolder: FileHolder) {
    fileHolder.serverResponse = { status: response.status, response };
    fileHolder.pending = false;

    this.uploadFinished.emit(fileHolder);

    if (--this.pendingFilesCounter === 0) {
      this.uploadStateChanged.emit(false);
    }
  }

  private processUploadedFiles() {
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      const data: any = this.uploadedFiles[i];

      let fileBlob: Blob;
      let file: File;
      let fileUrl: string;

      if (data instanceof Object) {
        fileUrl = data.url;
        fileBlob = (data.blob) ? data.blob : new Blob([data]);
        file = new File([fileBlob], data.fileName);
      } else {
        fileUrl = data;
        fileBlob = new Blob([fileUrl]);
        file = new File([fileBlob], fileUrl);
      }
      const fileName = file.name;
      this.files.push(new FileHolder(fileUrl, fileName.substring(fileName.lastIndexOf('/') + 1), file));
    }
  }

  private async uploadFiles(files: FileList, filesToUploadNum: number) {
    for (let i = 0; i < filesToUploadNum; i++) {
      const file = files[i];
      if (this.mustMatchExtension && this.supportedExtensions) {
        const reg = new RegExp(this.supportedExtensions);
        if (!reg.test(file.type)) {
          this.isInvalidExtension = true;
          this.fileCounter = 0;
          break;
        }
      }
      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.fileCounter--;
        this.inputElement.nativeElement.value = '';
        this.showFileTooLargeMessage = true;
        this.uploadStateChanged.emit(false);
        continue;
      }
      const metadata = { file, url: this.url, abort: false, formData: this.customFormData }
      const beforeUploadResult: UploadMetadata = await this.beforeUpload(metadata);

      if (beforeUploadResult.abort) {
        this.fileCounter--;
        this.inputElement.nativeElement.value = '';
        continue;
      }
      // const img = document.createElement('img') as HTMLImageElement;
      // img.src = window.URL.createObjectURL(beforeUploadResult.file);

      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        const fileName = beforeUploadResult.file.name;
        const fileHolder: FileHolder = new FileHolder(event.target.result,
          fileName.substring(fileName.lastIndexOf('/') + 1), beforeUploadResult.file);
        this.files.push(fileHolder);
        this.uploadSingleFile(fileHolder, beforeUploadResult.url, beforeUploadResult.formData);
      }, false);
      reader.readAsDataURL(beforeUploadResult.file);
    }
  }

  private async uploadSingleFile(fileHolder: FileHolder, url = this.url, customForm?: { [name: string]: any }) {
    if (url) {
      this.pendingFilesCounter++;
      fileHolder.pending = true;

      // this.imageService
      //   .uploadImage(url, fileHolder, this.compress, this.headers, this.partName, customForm, this.withCredentials)
      //   .subscribe(
      //     response => this.onResponse(response, fileHolder),
      //     error => {
      //       this.onResponse(error, fileHolder);
      //       this.deleteFile(fileHolder);
      //     });
      try {
        const response = await (await this.imageService.uploadImage(
          url, fileHolder, this.crop, this.ratio, this.rotate, this.compress,
          this.headers, this.partName, customForm, this.withCredentials
        )).toPromise();
        this.onResponse(response, fileHolder);
      } catch (error: any) {
        this.onResponse(error, fileHolder);
        this.deleteFile(fileHolder);
      }
    } else {
      this.uploadFinished.emit(fileHolder);
    }
  }
}
