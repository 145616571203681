import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { HeaderImageInfo } from '../entity';
import { AbstractHttpService } from 'projects/yka-base-common/src/public-api';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper';

@Injectable({
  providedIn: 'root'
})
export class HeaderImageService extends AbstractHttpService<HeaderImageInfo> {

  constructor(
    @Inject(BACKEND_URL) backendUrl: string,
    http: HttpClient
  ) {
    super(backendUrl, http, 'headerimage');
  }

  getByLang(lang: string) {
    return this.http.get<HeaderImageInfo[]>(`${this.backendUrl}${this.pathVar}/bylang/${lang}`);
  }

}
