<div class="footer">
  <div class="text-center mx-2 p-2 footer-header">
    <small> © 2023
      <span class="font-weight-bold">
        {{'yktech' | translate}}
      </span>
    </small>
  </div>
  <div *ngFor="let item of icps" class="d-flex justify-content-center mb-2">
    <small>
      <a class="mr-2" [href]="item.link" target="_blank" rel="noopener noreferrer">{{ item.name }}</a>
    </small>
  </div>
  <div *ngIf="friendLinks.length > 0" class="d-flex flex-column">
    <div class="d-flex justify-content-center">
      <small>
        {{ 'friendLink' | translate }}
      </small>
    </div>
    <div *ngFor="let item of friendLinks" class="d-flex justify-content-center mb-2">
      <small>
        <a class="mr-2" [href]="item.link" target="_blank" rel="noopener noreferrer">{{ item.name }}</a>
      </small>
    </div>
  </div>
</div>
