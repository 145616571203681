export function getOrientation(imageSrc: string, onRotationFound: (rotationInDegrees: number) => void) {
  const view = new DataView(base64ToArrayBuffer(imageSrc));

  if (view.getUint16(0, false) !== 0xffd8) {
    return onRotationFound(-2);
  }

  const length = view.byteLength;
  let offset = 2;

  while (offset < length) {
    if (view.getUint16(offset + 2, false) <= 8) {
      return onRotationFound(-1);
    }
    const marker = view.getUint16(offset, false);
    offset += 2;

    if (marker === 0xffe1) {
      if (view.getUint32((offset += 2), false) !== 0x45786966) {
        return onRotationFound(-1);
      }

      const little = view.getUint16((offset += 6), false) === 0x4949;
      offset += view.getUint32(offset + 4, little);
      const tags = view.getUint16(offset, little);
      offset += 2;
      for (let i = 0; i < tags; i++) {
        // 0x0112 -- orientation value: 1 - 0°, 6 - 90°, 8 - -90°, 3 - 180°
        if (view.getUint16(offset + i * 12, little) === 0x0112) {
          return onRotationFound(view.getUint16(offset + i * 12 + 8, little));
        }
      }
      // tslint:disable-next-line:no-bitwise
    } else if ((marker & 0xff00) !== 0xff00) {
      break;
    } else {
      offset += view.getUint16(offset, false);
    }
  }
  return onRotationFound(-1);
}

function base64ToArrayBuffer(base64: string) {
  base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
  const binary = atob(base64);
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return buffer;
}

function getAverageRGB(imgEl: any, defaultRGB?: any) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext && canvas.getContext('2d');
  let width;
  let height;
  if (!defaultRGB) {
    defaultRGB = {r: 0, g: 0, b: 0}; // for non-supporting envs
  }
  if (!context) {
      return defaultRGB;
  }
  height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
  context.drawImage(imgEl, 0, 0);
  return getAverageRGBFromContext(context, width, height, defaultRGB);
}

function getAverageRGBFromContext(context: CanvasRenderingContext2D, width: any, height: any, defaultRGB: any) {
  const blockSize = 5; // only visit every 5 pixels
  let i = -4;
  let data;
  let length;
  const rgb = {r: 0, g: 0, b: 0};
  let count = 0;

  try {
      data = context.getImageData(0, 0, width, height);
  } catch (e) {
      /* security error, img on diff domain */
      return defaultRGB;
  }

  length = data.data.length;
  while ( (i += blockSize * 4) < length ) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
  }

  rgb.r = Math.floor(rgb.r / count);
  rgb.g = Math.floor(rgb.g / count);
  rgb.b = Math.floor(rgb.b / count);

  return rgb;
}
