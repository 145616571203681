import { Component, OnInit, Input, Output, EventEmitter, forwardRef, DoCheck, Injector, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl, NgControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '../../ckeditor5-build-decoupled-document/build/translations/zh';
import '../../ckeditor5-build-decoupled-document/build/translations/zh-cn';
import DecoupledEditor from '../../ckeditor5-build-decoupled-document';

import { YkUploadAdapter } from './yk-upload-adapter';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { TranslateService } from '@ngx-translate/core';
import { IMAGE_URL, TokenStorageService } from 'projects/yka-base-common/src/public-api';

@Component({
  selector: 'yka-common-rich-editor',
  templateUrl: './rich-editor.component.html',
  styleUrls: ['./rich-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichEditorComponent),
      multi: true
    }
  ]
})
export class RichEditorComponent implements ControlValueAccessor, OnInit, DoCheck {
  private control?: AbstractControl;
  private oldValue: any;

  public Editor = DecoupledEditor;
  @Input() uploadUrl: string = '';
  @Input() imageUrl: string = '';
  @Input() customFormData?: { [name: string]: string };
  // tslint:disable-next-line: no-input-rename
  @Input('disabled') isDisabled?: boolean;
  @Input() config: any;
  @Input() toolbar = [] as string[];
  @Input() placeholder?: string;
  // tslint:disable-next-line: no-input-rename
  @Input('value') _value = '';
  @Output() dataChanged = new EventEmitter<string>();
  @Output() focus = new EventEmitter<string>();
  @Output() blur = new EventEmitter<string>();
  @Input()
  set id(value: string) {
    this._ID = value;
  }
  get id() {
    return this._ID;
  }

  private _ID = '';
  url = '';

  public onReady( editor: any ) {
    if (this.isDisabled) {
      return;
    }
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) => {
      return new YkUploadAdapter( loader, this.uploadUrl, this.http, this.customFormData );
    };
    const ngControl: NgControl = this.injector.get(NgControl, {});
    if (ngControl) {
      this.control = ngControl.control as AbstractControl;
    }
  }

  ngDoCheck(): void {
    this.oldValue = this._value;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  onFocus(event: any) {
    this.focus.emit(event);
  }

  onBlur(event: any) {
    this.blur.emit(event);
  }

  get value() {
    return this._value;
  }
  set value(val) {
    if (this.imageUrl) {
      this.defaultImageUrl = this.imageUrl;
    }
    const rs = this.uploadUrl ? this.uploadUrl.replace('/file', '') : '';
    this._value = val ? val.replace(new RegExp(rs, 'gm'), this.defaultImageUrl) : val;
    this.onChange(val);
    this.onTouched();
  }
  writeValue(obj: any): void {
    // if (!obj) {
    //   return;
    // }
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  public onDataChange( { editor }: ChangeEvent ) {
    if (editor) {
      this._value = editor.getData();
      this.onChange(this._value);
      this.dataChanged.emit(this._value);
      if (this.oldValue !== '' && this.control) {
        this.control.markAsDirty();
      }
      if (this._value === this.oldValue && this.control) {
        this.control.markAsPristine();
      } else if (this.oldValue?.length - this._value.length > 52) {
        //
        this.deleteUploadedImage(this.oldValue, this._value);
      }
    }
  }
  private deleteUploadedImage(oval: string, nval: string): void {
    const len = oval.length - nval.length;
    let i = 0;
    for (; i < nval.length; i++) {
      if (oval[i] !== nval[i]) {
        break;
      }
    }
    // index from found different char,
    // ckeditor auto add <p>&nbsp;</p> to the location after removing the image
    const diff = oval.substr(i - 1, len + 14);
    const re = new RegExp('(?<=(<figure class="image.*?"><img src=")).+?(?="><\/figure>)', 'g');
    const rlt = diff.match(re);

    if (rlt) {
      this.http.post(`${this.uploadUrl}/deletehttp`, rlt).subscribe(
        res => {},
        error => {}
      );
    }
  }

  constructor(
    private injector: Injector,
    @Inject(IMAGE_URL) private defaultImageUrl: string,
    private http: HttpClient,
    private tokenStorage: TokenStorageService,
    private translate: TranslateService) {
  }

  ngOnInit() {
    let lang = this.tokenStorage.getItem('lang');
    if (lang && lang.length > 0) {
      switch (lang) {
        case 'zh': 
          lang = 'zh-cn';
          break;
        case 'big5': 
          lang = 'zh';
          break;
        default:
          lang = 'en';
          break;
      }
    } else {
      lang = this.translate.getBrowserCultureLang();
      switch (lang) {
        case 'zh-CN': 
          lang = 'zh-cn';
          break;
        case 'zh-HK': 
        case 'zh-TW': 
          lang = 'zh';
          break;
        default:
          lang = 'en';
          break;
      }
    }
    this.config = {
      language: lang,
      placeholder: this.placeholder,
      fullPage: true,
      fontFamily: {
        options: [
          'default',
          '思源黑体 CN', '思源宋体 CN', 
          'Sabon LT Std', "Suisse Int'l"
        ]
      },
      fontSize: {
        options: [
          10, 11, 12, 'default', 14, 16, 18, 20, 22, 24, 26, 28, 36, 44, 48
        ]
      },
      toolbar: this.toolbar.length > 0 ? this.toolbar : {items: [
        'heading',
        '|',
        'fontsize',
        'fontfamily',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        //'-',
        '|',
        'outdent',
        'indent',
        'alignment',
        'imageStyle:full',
        'imageStyle:alignLeft',
        'imageStyle:alignRight',
        '|',
        'numberedList',
        'bulletedList',
        '|',
        'link',
        'blockquote',
        'imageUpload',
        'insertTable',
        // 'mediaEmbed',
        '|',
        'undo',
        'redo'
      ], shouldNotGroupWhenFull: true }
    };
  }

}
