import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent } from './component/alert/alert.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipe } from './helper/safe-html.pipe';
import { RichHtmlPipe } from './helper/rich-html.pipe';

@NgModule({
  declarations: [
    AlertComponent,
    SafeHtmlPipe,
    RichHtmlPipe
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    AlertComponent,
    TranslateModule,
    SafeHtmlPipe,
    RichHtmlPipe
  ]
})
export class YkaBaseCommonModule { }
