import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef,
  Renderer2, ChangeDetectorRef, NgZone, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'projects/yka-base-common/src/public-api';

/** Image Cropper Config */
export interface ImgCropperConfig {
  areaWidth?: string;
  areaHeight?: string;
  width?: number;
  height?: number;
  orient?: number;
  ratio?: number;
  type?: string;
}

interface ImgRect {
  x: number;
  y: number;
  xc: number;
  yc: number;
}

@Component({
  selector: 'yka-common-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('rootContainer', { static: true })
  rootContainer!: ElementRef;
  @ViewChild('imgContainer', { static: true })
  imgContainer!: ElementRef;
  @ViewChild('area', { static: false })
  croppingContainer!: ElementRef;
  @ViewChild('imgCanvas', { static: true })
  imgCanvas!: ElementRef<HTMLCanvasElement>;

  isMobile = Utils.isMobile;
  
  @Input()
  config!: ImgCropperConfig;
  @Input()
  imageSrc!: string;
  @Output()
  readonly cropped = new EventEmitter<string>();

  // When is loaded image & ready for crop
  // When is loaded image & ready for crop
  isLoaded!: boolean;
  croppedImage!: string;
  isCropping!: boolean;

  // Original image
  private offset?: {
    x: number
    y: number
    left: number
    top: number
  };
  private origScale = 1;
  private minScale!: number;
  private imgRect: ImgRect = {} as any;
  private listeners = new Set<Subscription>();
  private defaultType = 'image/jpeg';

  constructor(
    private dialogRef: MatDialogRef<ImageCropComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {imageSrc: string, config: ImgCropperConfig},
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.renderer.addClass(this.elementRef.nativeElement, 'root');
  }

  ngOnInit(): void {
    if (this.data) {
      this.imageSrc = this.data.imageSrc;
      this.config = this.data.config;
    }
    if (this.config.ratio) {
      this.config.areaWidth = '100%';
      const fw = window.innerWidth;
      const fh = Math.floor(fw * this.config.ratio);
      this.config.areaHeight = fh + 'px';
      this.config.width = Math.floor(fw * .7);
      this.config.height = Math.floor(this.config.width * this.config.ratio);
    }
    if (this.config.type) {
      this.defaultType = this.config.type;
    }
    this.setImageUrl(this.imageSrc);
  }

  ngAfterViewInit(): void {
    let initDistance: number;
    let initScale: number;
    let scale = 1;
    const hanlder = (evt: any) => {
      if (!initScale || initScale === 1) {
        initScale = this.minScale;
      }
      if (evt.touches.length < 2) {
        return;
      }
      const d = getDistance(evt.touches);
      if (!initDistance) {
        initDistance = d;
      }
      const delta = (d - initDistance) / initDistance / 5;
      scale = initScale + delta;
      if (scale > this.minScale) {
        this.setScale(scale);
      } else {
        this.fit();
      }
    };
    this.imgContainer.nativeElement.addEventListener('touchmove', hanlder);
    this.imgContainer.nativeElement.addEventListener('touchstart', () => { initDistance = 0; });
    this.imgContainer.nativeElement.addEventListener('touchend', () => { initScale = scale; });
  }

  ngOnDestroy() {
    this.listeners.forEach(listen => listen.unsubscribe());
    this.listeners.clear();
  }

  close() {
    this.dialogRef.close();
  }

  setImageUrl(src: string) {
    this.clean();

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = src;
    const loadListen = new Observable<void>(obs => {
      img.onerror = err => obs.error(err);
      img.onabort = err => obs.error(err);
      img.onload = () => {
        obs.next();
        obs.complete();
      };
    })
    .subscribe({
      next: () => {
        this.imgLoaded(img);
        this.cdr.markForCheck();

        this.ngZone
          .onStable
          .pipe(take(1))
          .subscribe(
            () => setTimeout(() => this.ngZone.run(() => {
              this.updateMinScale(this.imgCanvas.nativeElement);
              this.isLoaded = false;

              this.setScale(this.minScale);

              this.isLoaded = true;
              this.cdr.markForCheck();
            }), 500)
          );
        this.listeners.delete(loadListen);
        this.ngOnDestroy();
      },
      error: () => {
        this.listeners.delete(loadListen);
        this.ngOnDestroy();
      }
    });
    this.listeners.add(loadListen);
    // clear
    this.defaultType = '';
  }

  // Clean the img cropper
  clean() {
    if (this.isLoaded) {
      this.imgRect = { } as any;
      this.offset = undefined;
      this.origScale = 1;
      this.minScale = 1;
      this.isLoaded = false;
      const canvas = this.imgCanvas.nativeElement;
      canvas.width = 0;
      canvas.height = 0;
      this.cdr.markForCheck();
    }
  }

  moveStart() {
    this.offset = {
      x: this.imgRect.x,
      y: this.imgRect.y,
      left: this.imgRect.xc,
      top: this.imgRect.yc
    };
  }
  move(event: any) {
    let x: number | undefined;
    let y: number | undefined;
    const canvas = this.imgCanvas.nativeElement;
    const scaleFix = this.origScale;
    const config = this.config;
    const startP = this.offset;
    if (!scaleFix || !startP) {
      return;
    }

    if (config.height && config.width) {
      const isMinScaleY = canvas.height * scaleFix < config.height;
      const isMinScaleX = canvas.width * scaleFix < config.width;

      const limitLeft = (config.width / 2 / scaleFix) >= startP.left - (event.deltaX / scaleFix);
      const limitRight = (config.width / 2 / scaleFix) + (canvas.width) - (startP.left - (event.deltaX / scaleFix))
                          <= config.width / scaleFix;
      const limitTop = ((config.height / 2 / scaleFix) >= (startP.top - (event.deltaY / scaleFix)));
      const limitBottom = (((config.height / 2 / scaleFix) + (canvas.height) - (startP.top - (event.deltaY / scaleFix)))
                          <= (config.height / scaleFix));

      // Limit for left
      if ((limitLeft && !isMinScaleX) || (!limitLeft && isMinScaleX)) {
        x = startP.x + (startP.left) - (config.width / 2 / scaleFix);
      }

      // Limit for right
      if ((limitRight && !isMinScaleX) || (!limitRight && isMinScaleX)) {
        x = startP.x + (startP.left) + (config.width / 2 / scaleFix) - canvas.width;
      }

      // Limit for top
      if ((limitTop && !isMinScaleY) || (!limitTop && isMinScaleY)) {
        y = startP.y + (startP.top) - (config.height / 2 / scaleFix);
      }

      // Limit for bottom
      if ((limitBottom && !isMinScaleY) || (!limitBottom && isMinScaleY)) {
        y = startP.y + (startP.top) + (config.height / 2 / scaleFix) - canvas.height;
      }

      if (x === void 0) { x = (event.deltaX / scaleFix) + (startP.x); }
      if (y === void 0) { y = (event.deltaY / scaleFix) + (startP.y); }
      this.setStylesForContImg({x, y});
    }
  }

  /**
   * Crop Image
   */
  crop(config?: ImgCropperConfig): void {
    this.isCropping = true;
    this.cdr.detectChanges();
    const newConfig = config ?  {...config} : this.config;
    if (newConfig.height && newConfig.width) {
      const canvasElement: HTMLCanvasElement = document.createElement('canvas');
      const left = this.imgRect.xc - (newConfig.width / 2 / this.origScale);
      const top = this.imgRect.yc - (newConfig.height / 2 / this.origScale);
      canvasElement.width = newConfig.width / this.origScale;
      canvasElement.height = newConfig.height / this.origScale;
      const ctx = canvasElement.getContext('2d');
      if (ctx) {
        ctx.drawImage(this.imgCanvas.nativeElement, -(left), -(top));
      }
      
      const url = canvasElement.toDataURL(this.defaultType);

      this.croppedImage = url;
      this.cropped.emit(url);
      this.isCropping = false;
      this.dialogRef.close(this.croppedImage);
    }
  }

  zoomIn() {
    const scale = this.origScale + .05;
    if (scale > 0) {
      this.setScale(scale);
    } else {
      // this.setScale(2);
    }
  }

  zoomOut() {
    const scale = this.origScale - .05;
    if (scale > this.minScale && scale <= 1) {
      this.setScale(scale);
    } else {
      this.fit();
    }
  }

  setScale(size?: number) {
    // check
    const changed = size != null && size !== this.origScale && size !== this.origScale;
    this.origScale = size || 1;
    if (!changed) {
      return;
    }
    if (this.isLoaded) {
      if (changed) {
        const originPosition = {...this.imgRect};
        this.offset = {
          x: originPosition.x,
          y: originPosition.y,
          left: originPosition.xc,
          top: originPosition.yc
        };
        this.setStylesForContImg({});
        this.move({
          deltaX: 0,
          deltaY: 0
        });
      } else {
        return;
      }
    } else if (this.minScale) {
      this.setStylesForContImg({
        ...this.getCenterPoints()
      });
    } else {
      return;
    }

  }

  private getCenterPoints() {
    const img = this.imgCanvas.nativeElement;
    const rootRect = this.rootRect();
    const x = (rootRect.width - (img.width)) / 2;
    const y = (rootRect.height - (img.height)) / 2;
    return {
      x,
      y
    };
  }

  fit() {
    this.setScale(this.minScale);
  }

  private rootRect(): DOMRect {
    return this.rootContainer.nativeElement.getBoundingClientRect() as DOMRect;
  }

  private setStylesForContImg(values: {
    x?: number
    y?: number
  }) {
    const newStyles = { } as any;
    if (values.x !== void 0 && values.y !== void 0) {
      const rootRect = this.rootRect();
      const x = rootRect.width / 2 - (values.x);
      const y = rootRect.height / 2 - (values.y);

      this.imgRect.x = (values.x);
      this.imgRect.y = (values.y);
      this.imgRect.xc = (x);
      this.imgRect.yc = (y);

    }
    newStyles.transform = `translate3d(${(this.imgRect.x)}px,${(this.imgRect.y)}px, 0)`;
    newStyles.transform += `scale(${this.origScale})`;
    newStyles.transformOrigin = `${this.imgRect.xc}px ${this.imgRect.yc}px 0`;
    newStyles['-webkit-transform'] = newStyles.transform;
    newStyles['-webkit-transform-origin'] = newStyles.transformOrigin;
    for (const key in newStyles) {
      if (newStyles.hasOwnProperty(key)) {
        this.renderer.setStyle(this.imgContainer.nativeElement, key, newStyles[key]);
      }
    }
  }

  private updateMinScale(canvas: HTMLCanvasElement) {
    const config = {...this.config};
    if (config.height && config.width) {
      this.minScale = Math.max(
        config.width / canvas.width,
        config.height / canvas.height);
    }
  }

  private imgLoaded(imgElement: HTMLImageElement) {
    if (imgElement) {
      const canvas = this.imgCanvas.nativeElement;
      const ort = this.config.orient;
      const w = imgElement.width;
      const h = imgElement.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // set proper canvas dimensions before transform & export
        if (ort && 4 < ort && ort < 9) {
          canvas.width = h;
          canvas.height = w;
        } else {
          canvas.width = w;
          canvas.height = h;
        }
        // transform context before drawing image
        switch (ort) {
          case 2: ctx.transform(-1, 0, 0, 1, w, 0); break;
          case 3: ctx.transform(-1, 0, 0, -1, w, h); break;
          case 4: ctx.transform(1, 0, 0, -1, 0, h); break;
          case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
          case 6: ctx.transform(0, 1, -1, 0, h, 0); break;
          case 7: ctx.transform(0, -1, -1, 0, h, w); break;
          case 8: ctx.transform(0, -1, 1, 0, 0, w); break;
          default: break;
        }
        ctx.drawImage(imgElement, 0, 0, w, h);

        /** set min scale */
        this.updateMinScale(canvas);
      }
    }
  }

}

export function getDistance(touches: any[]) {
  const d = Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) +
      Math.pow(touches[0].clientY - touches[1].clientY, 2));
  return d;
}

