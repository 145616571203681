import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { ErrorTranslatorService } from './error-translator.service';
import { Utils } from '../helper/utils';
import { BACKEND_URL } from '../helper/yk-token';
import { TokenStorageService } from './token-storage.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  isWeChat: boolean;

  constructor(
    @Inject(BACKEND_URL) private backendUrl: string,
    private router: Router,
    private authenticationService: AuthenticationService,
    private errService: ErrorTranslatorService,
    private tokenStorage: TokenStorageService
    ) 
  {
      let authType: string = '';
      if (typeof window != 'undefined') {
        authType = this.tokenStorage.getItem('authType') || '';
      }
      this.isWeChat = !authType && Utils.isWeChat();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let option: any = {
      withCredentials: true
    };
    if (req.url.indexOf('ampache') > 0) {
      option = {
        observe: 'body',
        responseType: 'text' as 'json'
      };
    }
    const request = req.clone(option);
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 || err.status === 403) {
        if (err.url.indexOf(this.backendUrl) > -1) {
          let returnUrl = this.router.url;
          if (!returnUrl || returnUrl.startsWith('/login')) {
            returnUrl = '/';
          }
          if (Utils.systemId === 'mb' && this.isWeChat) {
            // for weixin official account, need to re-enter from the weixin menu
            let token = '';
            if (typeof window != 'undefined') {
              token = this.tokenStorage.getToken();
            }
            const code = '';
            const wxState = '';
            this.authenticationService.wxLogin(code, wxState, token, returnUrl);
          } else {
            const  param = {
              error: this.errService.errorTranslation('ERR01010'),
              returnUrl: ''
            };
            if (!this.router.url.startsWith('/login')) {
              param.returnUrl = this.router.url;
            }
            this.router.navigate(['/login'],
              { queryParams: param });
          }
        }
      }
      if (err.error?.content || (err.statusText && err.statusText !== 'OK')) {
        const error = err.error?.content || err.statusText;
        return throwError(this.errService.errorTranslation(error));
      } else {
        return throwError(err);
      }
      
    }));
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
];
