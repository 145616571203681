import { HostListener, Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BACKEND_URL } from '../helper';
import { AlertService } from './alert.service';
import { WxTransferOrderInfo, WxPayOrderInfo } from '../entity';

declare var WeixinJSBridge: any;

@Injectable({
  providedIn: 'root'
})
export class WxPayOrderService {
  wx: any;

  prepayInfo: any;

  constructor(
    @Inject(BACKEND_URL) private backendUrl: string,
    private http: HttpClient,
    private alertService: AlertService) {
    this.wx = (window as { [key: string]: any })['wx'];
  }

  transfer(info: WxTransferOrderInfo) {
    return this.http.post<string>(`${this.backendUrl}wxpay/transfer`, info);
  }

  prepay(info: WxPayOrderInfo) {
    return this.http.post<string>(`${this.backendUrl}wxpay/prepay`, info);
  }

  async pay(info: WxPayOrderInfo) {
    try {
      const isMiniProgram = /miniProgram/i.test(navigator.userAgent);
      this.prepayInfo = await this.prepay(info).toPromise();
      if (isMiniProgram) {
        this.wx.miniProgram.postMessage({data: {prepayInfo: this.prepayInfo, amount: info.totalFee}});
        this.wx.miniProgram.redirectTo({url: '/pages/pay/pay'});
        return 'miniProgram';
      } else {
        const res = await this.onBridgeReady();
        if (res && res.err_msg === 'get_brand_wcpay_request:ok') {
          // Pay Successfully
          return 'success';
        } else if (res && res.err_msg === 'get_brand_wcpay_request:cancel') {
          // Pay Canceled
          return 'cancel';
        } else if (res && res.err_msg === 'get_brand_wcpay_request:fail') {
          // Pay Failed
          WeixinJSBridge.call('closeWindow');
          return 'fail';
        } else {
          // res not return
          return 'error';
        }
      }
    } catch (error: any) {
      this.alertService.error(error);
      return 'error';
    }
  }

  @HostListener('WeixinJSBridgeReady')
  onBridgeReady() {
    return new Promise<any>((resolve) => WeixinJSBridge.invoke('getBrandWCPayRequest',
      {
        appId: this.prepayInfo.appId, // 公众号名称,由商户传入
        timeStamp: this.prepayInfo.timeStamp, // 时间戳,自1970年以来的秒数
        nonceStr: this.prepayInfo.nonceStr, // 随机串
        package: this.prepayInfo.package,
        signType: this.prepayInfo.signType, // 微信签名方式：
        paySign: this.prepayInfo.paySign // 微信签名
      },
      (res: any) => {
        resolve(res);
      }
    ));
  }

}
