
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageCategoryInfo, RecruitmentInfo } from 'projects/share-lib/src/lib/entity';
import { PageCategoryService, RecruitmentService } from 'projects/share-lib/src/lib/service';
import { TokenStorageService } from 'projects/yka-base-common/src/public-api';

@Component({
  selector: 'app-recruitment',
  templateUrl: './recruitment.component.html',
  styleUrls: ['./recruitment.component.scss']
})
export class RecruitmentComponent implements OnInit {

  id: number = -1;

  parent = {} as PageCategoryInfo;
  category = {} as PageCategoryInfo;
  item: RecruitmentInfo = {} as RecruitmentInfo;

  constructor(
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private translate: TranslateService,
    private pcService: PageCategoryService,
    private service: RecruitmentService,
    // private datePipe: DatePipe,
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async param => {
      this.id = Number(param.get('id'));
      await this.getItem();
    });
  }

  async getItem() {
    this.item = await this.service.getById(this.id).toPromise();
    // this.item.createdTime = this.datePipe.transform(new Date(this.item.createdTime), 'yyyy-MM-dd HH:mm:ss') || '';
    this.category = await this.pcService.getById(this.item.category).toPromise();
    if (this.category.parent) {
      this.parent = await this.pcService.getById(this.category.parent).toPromise();
    } else {
      this.parent.id = this.category.id;
    }
  }
  
}
