import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdInfo } from 'projects/yka-base-common/src/lib/entity';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService<T extends IdInfo> {

  constructor() { }

  getFormData(form: FormGroup, item: T, raw?: boolean) {
    const c = raw ? form.getRawValue() : form.value;
    Object.keys(c).forEach(k => {
      let v = c[k];
      if ((typeof v) === 'string') {
        v = v.trim();
      }
      item[k as keyof T] = v;
    });
    return item;
  }

  setFormData(form: FormGroup, item: T) {
    const c = form.controls;
    Object.keys(c).forEach(k => {
      // filter array control out
      if (c[k] && !((c[k] as any)['controls'] instanceof Array)) {
        c[k].setValue(item[k as keyof T]);
      }
    });
  }

}
