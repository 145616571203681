import { InjectionToken, Injectable } from '@angular/core';

export const BACKEND_URL = new InjectionToken<string>('backendUrl');
export const IMAGE_URL = new InjectionToken<string>('imageUrl');

@Injectable()
export class DataProvider {
  public param: any;

  constructor() {}
}
