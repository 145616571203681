import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RichEditorComponent } from './rich-editor.component';

@NgModule({
  declarations: [
    RichEditorComponent
  ],
  imports: [
    FormsModule,
    CKEditorModule
  ],
  exports: [
    RichEditorComponent
  ]
})
export class RichEditorModule {}