import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AdPageInfo } from '../entity/ad-page-info';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper/yk-token';
import { AbstractHttpService } from 'projects/yka-base-common/src/lib/service/abstract-http.service';

@Injectable({
  providedIn: 'root'
})
export class AdPageService extends AbstractHttpService<AdPageInfo> {

  constructor(
    @Inject(BACKEND_URL) backendUrl: string,
    http: HttpClient
  ) {
    super(backendUrl, http, 'adpage');
  }

  findByLocation(locations: string[], topNum?: string) {
    let params;
    if (topNum) {
      params = {locations, topNum};
    } else {
      params = {locations};
    }

    return this.http.get<AdPageInfo[]>(`${this.backendUrl}${this.pathVar}/bylocation`, { params });
  }

  findByLocationAndMerchant(location: string, merchant: number) {
    const params = {location, merchant: merchant.toString()};

    return this.http.get<AdPageInfo[]>(`${this.backendUrl}${this.pathVar}/bylocationandmerchant`, { params });
  }

  findByLocationAndStatus(location: string, status: string) {
    const params = {location, status};

    return this.http.get<AdPageInfo[]>(`${this.backendUrl}${this.pathVar}/bylocationandstatus`, { params });
  }

  findAds() {
    return this.http.get<AdPageInfo[]>(`${this.backendUrl}${this.pathVar}/ads`);
  }
}
