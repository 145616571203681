<app-header></app-header>

<div class="d-flex justify-content-center container">
  <div class="category">{{category | translate}}</div>
</div>

<app-nav-loc-bar [page]="currentPage"></app-nav-loc-bar>

<div class="row container content-row">
  <div class="col">
    <div class="row">
      <div class="col">
        <div class="mb-2 elm-title">{{'ourContact' | translate}}</div>
        <div class="d-flex flex-column">
          <div class="d-flex contact-item">
            <div class="sub-title-label">{{'address' | translate}}:</div>
            <div class="ml-2">{{yitem.address}}</div>
          </div>
          <div class="d-flex contact-item">
            <div class="sub-title-label">{{'postCode' | translate}}:</div>
            <div class="ml-2">{{yitem.postCode}}</div>
          </div>
          <div class="d-flex contact-item">
            <div class="sub-title-label">{{'tel' | translate}}:</div>
            <div class="ml-2">{{yitem.tel}}</div>
          </div>
          <div class="d-flex contact-item">
            <div class="sub-title-label">{{'fax' | translate}}:</div>
            <div class="ml-2">{{yitem.fax}}</div>
          </div>
          <div class="d-flex contact-item">
            <div class="sub-title-label">{{'cellPhone' | translate}}:</div>
            <div class="ml-2">{{yitem.cellPhone}}</div>
          </div>
          <div class="d-flex contact-item">
            <div class="mr-2 sub-title-label">{{'email' | translate}}:</div>
            <a href="mailto:{{yitem.email}}">{{yitem.email}}</a>
          </div>
          <div class="d-flex contact-item">
            <div class="mr-2 sub-title-label">{{'complaintEmail' | translate}}:</div>
            <a href="mailto:{{yitem.complaintEmail}}">{{yitem.complaintEmail}}</a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="elm-title">{{'yourRequest' | translate}}</div>
        <form [formGroup]="itemForm" class="d-flex flex-column">
          <mat-form-field>
            <input matInput formControlName="name" placeholder="{{'customerName' | translate}}" required/>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="title" placeholder="{{'customerTitle' | translate}}" />
          </mat-form-field>
          <mat-form-field>
            <input matInput type="tel" formControlName="tel" placeholder="{{'tel' | translate}}" required/>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="email" formControlName="email" placeholder="{{'email' | translate}}" />
          </mat-form-field>
          <mat-form-field>
            <textarea style="line-height: 1rem;" matInput formControlName="content" placeholder="{{'content' | translate}}"
                    cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5"></textarea>
          </mat-form-field>
          <button class="submit-btn" mat-raised-button color="primary" [disabled]="itemForm.invalid" (click)="saveItem()">{{'submit' | translate}}</button>
        </form>    
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>