import { Inject, Pipe, PipeTransform } from '@angular/core';
import { IMAGE_URL } from './yk-token';

@Pipe({ name: 'richHtml' })
export class RichHtmlPipe implements PipeTransform {
  constructor(@Inject(IMAGE_URL) public imageUrl: string,) {
  }

  transform(value: string) {
    return this.convertRichText(value);
  }

  convertRichText(src: string) {
    src = src.replace(/figure/g, 'div')
    src = src.replace(/\<img/gi,'<img style="margin: 0 auto;max-width: 100%;min-width: 50px;display:block;"');
    src = src.replace(/\<a/gi,'<a style="color: #007bff;text-decoration: none;background-color: transparent;"');
    src = src.replace(/\<p/gi,'<p style="margin: 8px 0px;"');
    src = src.replace(/&nbsp;/g,'\xa0');
    src = src.replace(/<table>/g, '<table style="border-collapse: collapse;border: 1px solid black;">');
    src = src.replace(/<td>/g, '<td style="border: .5px solid black;min-width:20px;">');
    src = src.replace(/<td /g, '<td style="border: .5px solid black;min-width:20px;" ');
    src = src.replace(/http.*?image/g, this.imageUrl + '/image');
    return src;
  }
}

