import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './main/page/contact-us/contact-us.component';
import { HomeComponent } from './main/page/home/home.component';
import { NewsPageComponent } from './main/page/news-page/news-page.component';
import { RecruitmentComponent } from './main/page/recruitment/recruitment.component';
import { SimplePageComponent } from './main/page/simple-page/simple-page.component';
import { WebpageComponent } from './main/page/webpage/webpage.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'list/:type/:parent/:id', component: NewsPageComponent },
  { path: 'webpage/:id', component: WebpageComponent },
  { path: 'simple-page/:id', component: SimplePageComponent },
  { path: 'recruitment/:id', component: RecruitmentComponent },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
