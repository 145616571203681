<div *ngIf="list"  (click)="scrollToTop()"
  class="scroll-to-top d-flex align-items-center justify-content-center"
  [ngClass]="{'show-scrollTop': windowScrolled}">
  <div class="btn-text">
      <mat-icon svgIcon="totop"></mat-icon>
      <!-- {{'backToTop' | translate}} -->
  </div>
</div>
<div *ngFor="let item of items; let i=index;" (click)="onItemClick(i)" 
  class="item-icon d-flex align-items-center justify-content-center"
  [ngStyle]="{'bottom': (list ? (i + 1) * 48 : 0) + 100 + 'px' }">
  <div *ngIf="item.count && item.count > 0" class="rounded-circle icon-pill">{{item.count}}</div>
  <div class="btn-text">
      <mat-icon [svgIcon]="item.icon"></mat-icon>
      <!-- {{'backToTop' | translate}} -->
  </div>
</div>

