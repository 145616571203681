import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { FileDropDirective } from './file-drop.directive';
import { ImageUploadService } from './image-upload.service';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageCropService } from '../image-crop/image-crop.service';
import { ImageCropComponent } from '../image-crop/image-crop.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [CommonModule, MatIconModule, MatProgressSpinnerModule],
  declarations: [
    ImageUploadComponent,
    FileDropDirective,
    ImageCropComponent
  ],
  exports: [ImageUploadComponent, ImageCropComponent]
})
export class ImageUploadModule {
  static forRoot(): ModuleWithProviders<ImageUploadModule> {
    return {
      ngModule: ImageUploadModule,
      providers: [ImageUploadService, ImageCropService]
    };
  }
}
