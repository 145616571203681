import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export class YkUploadAdapter {
  loader: any;
  subscription!: Subscription;
  formData?: { [name: string]: string };

  constructor(
    loader: any,
    private url: string,
    private http: HttpClient,
    formData?: { [name: string]: string }
  ) {
    this.loader = loader;
    this.formData = formData;
  }

  upload() {
    const that = this;
    return this.loader.file.then((file: any) =>
      new Promise((resolve, reject) => {
        this.subscription = this.initRequest(file).subscribe(res => {
          if (res) {
            const url = that.url.replace('file', res['content']);
            resolve({ default: url });
          }
        },
        err => {
          reject(err);
        });
      }));

    // ************** the following is wrong code *******************
    // {
    //   this.subscription = this.initRequest(file).subscribe(res => {
    //     if (res) {
    //       return Promise.resolve({ default: res });
    //     }
    //   },
    //   err => {
    //     return Promise.reject(err);
    //   });
    // });
  }

  // Aborts the upload process.
  abort() {
    this.subscription.unsubscribe();
  }

  initRequest(file: any) {
    const loader = this.loader;
    const formData = new FormData();

    formData.append('image', file);
    if (this.formData) {
      for (const key of Object.keys(this.formData)) {
        formData.append(key, this.formData[key]);
      }
    }

    return this.http.post(this.url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event: any) => {

      switch (event.type) {
        case HttpEventType.UploadProgress:
          loader.uploadTotal = event.total;
          loader.uploaded = event.loaded;
          break;
        case HttpEventType.Response:
          return event.body;
      }
    })
    );
  }
}
