import { Injectable } from '@angular/core';
import { ImageCropComponent, ImgCropperConfig } from './image-crop.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ImageCropService {
  constructor(public dialog: MatDialog) { }

  async openCropDialog(data: {imageSrc: string, config: ImgCropperConfig}): Promise<string> {
    const dialogRef = this.dialog.open(ImageCropComponent,
      {position: {left: '0', top: '2rem'}, panelClass: 'crop-dialog-container', autoFocus: false, width: '100%', maxWidth: '100%', data});

    const rlt = await dialogRef.afterClosed().toPromise();
    return rlt;
  }
}
