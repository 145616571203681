import { Component, ElementRef, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TopMenuService } from 'projects/share-lib/src/lib/service';
import { TokenStorageService } from 'projects/yka-base-common/src/public-api';
import { ValueSettingService } from 'projects/yka-common/src/public-api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web';

  builtinContactUs: boolean = true;
  colors!: any;

  constructor(
    private el: ElementRef, private renderer:Renderer2,
    translate: TranslateService,
    private iconRegistry: MatIconRegistry,
    private tokenStorage: TokenStorageService,
    private sanitizer: DomSanitizer,
    private titleService: Title,

    private ssService: ValueSettingService,
    private tmService: TopMenuService,
  ) {
    translate.addLangs(['zh', 'big5', 'en']);
    const bcl = translate.getBrowserCultureLang(); // 'zh-HK', 'zh-TW', 'zh-CN', 'en-US'
    let bl = translate.getBrowserLang();
    if (bl === 'zh') {
      bl = bcl === 'zh-CN' ? 'zh' : 'big5';
    } else if (bl === 'en') {
      bl = 'en';
    } else {
      bl = 'zh';
    }let lang = this.tokenStorage.getItem('lang');
    lang = lang ? lang : bl;
    translate.use(lang ? lang : 'zh');
    this.iconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('./assets/img/yk.svg'));

    this.getSystemSetting();
    this.getTopMenuSetting();
    translate.get('applicationTitle').subscribe(res => {
      this.titleService.setTitle(res);
    });
  }

  getSystemSetting() {
    this.ssService.findByNameIn(['headerImageRatio', 'headerImageRatioOther', 'useBuiltinContactUs', 'fontColor', 'aColor', 
      'bgColor', 'hoverColor', 'hoverBgColor', 'bodyBgColor']).subscribe(ss => {
      if (ss) {
        const cs = {} as any;
        for (const itm of ss) {
          if (itm.name === 'headerImageRatio') {
            this.tokenStorage.saveItem('headerImageRatio', itm.stringValue);
          } else if (itm.name === 'headerImageRatioOther') {
            this.tokenStorage.saveItem('headerImageRatioOther', itm.stringValue);
          } else if (itm.name === 'useBuiltinContactUs') {
            this.builtinContactUs = itm.numberValue === 1;
            this.tokenStorage.saveItem('builtinContactUs', itm.numberValue + '');
          } else {
            cs[itm.name] = itm.stringValue;
          }
        }
        this.tokenStorage.saveObjectItem('colors', cs);
        this.renderer.setStyle(this.el.nativeElement.ownerDocument.body,'backgroundColor', cs.bodyBgColor);
        document.documentElement.style.setProperty('--a-color', cs.hoverColor);
        document.documentElement.style.setProperty('--a-bg-color', cs.hoverBgColor);
      }
    });
  }

  getTopMenuSetting() {
    this.tmService.getAll().subscribe(items => {
      if (items && items.length > 0) {
        this.tokenStorage.saveObjectItem('topMenu', items[0]);
      }
    });
  }

}