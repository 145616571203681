import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ValueSettingInfo } from '../entity/value-setting-info';
import { AbstractHttpService } from 'projects/yka-base-common/src/lib/service/abstract-http.service';
import { BACKEND_URL } from 'projects/yka-base-common/src/lib/helper/yk-token';

@Injectable({
  providedIn: 'root'
})
export class ValueSettingService  extends AbstractHttpService<ValueSettingInfo> {
  constructor(@Inject(BACKEND_URL) backendUrl: string, http: HttpClient) {
    super(backendUrl, http, 'systemsettings');
  }

  findByName(name: string) {
    return this.http.get<ValueSettingInfo>(`${this.backendUrl}${this.pathVar}/byname/${name}`);
  }

  findByNameIn(names: string[]) {
    return this.http.get<ValueSettingInfo[]>(`${this.backendUrl}${this.pathVar}/bynamein`, {params: {names}});
  }

}
