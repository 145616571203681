import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BACKEND_URL } from '../helper';

@Injectable({
  providedIn: 'root'
})
export class WxOAService {
  pathVar = 'wxoa';

  constructor(
    @Inject(BACKEND_URL) private backendUrl: string,
    private http: HttpClient,) {
  }

  checkOa() {
    return this.http.get(`${this.backendUrl}${this.pathVar}/checkoa`);
  }

}
