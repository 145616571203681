import { Component, Input } from '@angular/core';

@Component({
  selector: 'yka-common-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss']
})
export class ScrollToTopComponent {
  @Input() list: any;

  windowScrolled = true;

  constructor() {}

  scrollToTop() {
    this.list.scrollIntoView();
  }

}
