import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorTranslatorService {
  errText: any;
  errKeys: string[];

  constructor(private translate: TranslateService,
    ) {
    this.translate.get('error').subscribe((res) => {
      this.errText = res;
    });
    this.errKeys = [
      'ERR01001',
      'ERR01002',
      'ERR01003',
      'ERR01004',
      'ERR01005',
      'ERR01010',
      'ERR02001'
    ];
  }

  errorTranslation(err: string) {
    const nvc = 'NeedValidateCode';
    const needValidateCode = err.indexOf(nvc) > -1;
    const error = err.replace(nvc, '');
    let rlt = '';
    if (this.errKeys.includes(error.slice(0, 8))) {
      rlt = this.errText[error.slice(0, 8)];
    } else if (error.startsWith('Bad credentials')) {
      rlt = this.errText.wrongPassword;
    } else if (error.startsWith('Unknown Error')) {
      rlt = this.errText.unknownError;
    } else if (error.startsWith('OK')) {
      rlt = this.errText.OKError;
    } else {
      rlt = error;
    }
    if (needValidateCode) {
      rlt = nvc + rlt;
    }
    return rlt;
  }
}
