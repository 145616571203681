
export class MapHelper {
  static getNeSw(points: {lat: string, lng: string}[]) {
    const ne = {lat: 0, lng: 0};
    const sw = {lat: 90, lng: 180};
    for (const loc of points) {
      if (Number(loc.lat) < sw.lat) { sw.lat = Number(loc.lat); }
      if (Number(loc.lng) < sw.lng) { sw.lng = Number(loc.lng); }
      if (Number(loc.lat) > ne.lat) { ne.lat = Number(loc.lat); }
      if (Number(loc.lng) > ne.lng) { ne.lng = Number(loc.lng); }
    }
    return {ne, sw};
  }
}