import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from 'projects/yka-base-common/src/lib/helper/utils';


@Pipe({
  name: 'getNameById'
})
export class GetNameByIdPipe implements PipeTransform {

  transform(value: number, items?: any[]): string {
    if (null === value || !value || !items) return '';
    let item = Utils.getElementById(items, value);
    if (null === item) return '';
    return item.name;
  }

}
