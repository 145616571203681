import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'yka-common-floot-action',
  templateUrl: './floot-action.component.html',
  styleUrls: ['./floot-action.component.scss']
})
export class FlootActionComponent implements OnInit {
  @Input() list: any;
  @Input() items!: any[];
  @Output() itemClick = new EventEmitter<any>();

  constructor() { }
  
  windowScrolled = true;

  ngOnInit(): void {
    // document.documentElement.style.setProperty('--icon-bottom', '60px');
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // console.log(changes);
    // if (changes.merchant) {
    //   await this.getSold();
    // }
  }

  scrollToTop() {
    this.list.scrollIntoView();
  }

  onItemClick(idx: any) {
    this.itemClick.emit(idx);
  }

}
