import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageCategoryInfo, RecruitmentInfo, WebpageInfo } from 'projects/share-lib/src/lib/entity';
import { PageCategoryService, RecruitmentService, WebpageService } from 'projects/share-lib/src/lib/service';
import { BACKEND_URL, PageInfo, Utils } from 'projects/yka-base-common/src/lib/helper';
import { TokenStorageService } from 'projects/yka-base-common/src/public-api';
import { of } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;
  resultsLength = 0;
  isLoadingResults = true;

  linkMap!: Map<number, string>;

  h2t = Utils.html2text;
  
  type!: string;
  parentId: number = -1;
  categoryId: number = -1;
  ids: number[] = [];

  parent = {} as PageCategoryInfo;
  category = {} as PageCategoryInfo;
  scitems: PageCategoryInfo[] = [];
  items: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(BACKEND_URL) public backendUrl: string,
    private tokenStorage: TokenStorageService,
    private translate: TranslateService,
    private pcService: PageCategoryService,
    private service: WebpageService,
    private recruitmentService: RecruitmentService
  ) { }

  async ngOnInit() {
    this.route.paramMap.subscribe(async param => {
      this.type = param.get('type') || '';
      this.parentId = Number(param.get('parent'));
      this.categoryId = Number(param.get('id'));
      const sm = this.tokenStorage.getItem('linkMap');
      this.linkMap = sm ? new Map(JSON.parse(sm)) : new Map<number, string>();
      await this.getCategory();
      this.getItems();
    });
  }

  async getCategory() {
    this.scitems = [];
    this.ids = [this.categoryId];
    // use parent
    if (this.parentId !== this.categoryId) {
      const items = await this.pcService.getByParent(this.parentId).toPromise();
      this.ids = [];
      for (const itm of items) {
        this.scitems.push(itm);
        this.ids.push(itm.id);
      }
    }
    let id = this.parentId;
    this.category = await this.pcService.getById(id).toPromise();
    if (this.categoryId > 0 && this.parentId !== this.categoryId) {
      id = this.categoryId;
      this.ids = [id];
      this.parent = await this.pcService.getById(this.parentId).toPromise();
    } else {
      this.parent = {} as PageCategoryInfo;
    }
  }

  getItems() {
    const pi = {
      totalElements: this.paginator.length,
      page: this.paginator.pageIndex,
      size: this.paginator.pageSize,
      sort: 'id'
    } as PageInfo;

    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          pi.page = this.paginator.pageIndex;
          pi.size = this.paginator.pageSize;
          if (this.linkMap.get(this.parentId) === 'layout-3') {
            return this.recruitmentService.getByLangPage(this.translate.currentLang, pi);
          } else {
            return this.service.queryByCategoryInWithPage(this.ids, pi);
          }
          
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.totalElements;
          return data.content;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      ).subscribe(data => {
        const items = data;
        if (items && items.length === 1) {
          let rl = '/webpage';
          switch (this.linkMap.get(this.parentId)) {
            case 'layout-3':
              rl = '/recruitment';
              break;
            case 'layout-4':
              rl = '/simple-page';
              break;
            default:
              rl = '/webpage';
          }
          this.router.navigate([rl, items[0].id]);
        } else {
          this.items = items;
        }
      });
  }


}
