import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    TranslateModule
  ],
  exports: [ConfirmComponent]
})
export class ConfirmModule { }