<div class="images" [style.height]="ratio" [style.border-radius]="radius">
  <img  class="image" *ngFor="let item of items; let i=index"
    (click)="adClicked(item)"
    [src]="item.src"
    [@imageMove]="ImageState(i)"
    (swipeleft)="swipe($event.type)"
    (swiperight)="swipe($event.type)" 
    [style.object-fit]="objectFit"
    [style.max-width]="maxWidth" [style.max-height]="maxHeight">
</div>
<div *ngIf="items && items.length > 1" class="indicator d-flex justify-content-center w-100">
  <div class="d-flex flex-row align-items-center">
    <div *ngFor="let item of items; let i=index" class="mx-1"
      [class.item-active]="i === current" >&bull;</div>
  </div>
</div>
